import { Component, OnInit } from '@angular/core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { DiccionarioService } from '../diccionario.service';

@Component({
  selector: 'app-diccionario-gasto',
  templateUrl: './diccionario-gasto.component.html',
  styleUrls: ['./diccionario-gasto.component.css']
})
export class DiccionarioGastoComponent implements OnInit {
  diccionario: any;
  public showContainer: boolean;

  constructor(public breakpointObserver: BreakpointObserver, private diccionarioService: DiccionarioService) { }

  ngOnInit(): void {
    this.diccionarioService.getDiccionarioGasto().subscribe(data => {
      this.diccionario = data;
    });

    this.breakpointObserver
      .observe(['(max-width:40em)'])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.showContainer = true;
        } else {
          this.showContainer = false;
        }
      });
  }

  openCsvGastos() {
    window.open('assets/docs/diccionario-gastos.csv', '_blank');
  }

    openCsvGastosAdjuntos() {
      window.open('assets/docs/diccionario-gastos-adjuntos.csv', '_blank');
    }
}
