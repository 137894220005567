import { Component, OnInit, EventEmitter, Output, OnDestroy, ViewChild, Renderer2, ElementRef, Input } from '@angular/core';
import { Beneficiario } from '../beneficiario/model/beneficiario.model';
import { BeneficiarioService } from '../beneficiario/beneficiario.service';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Entidad } from '../entidad/model/entidad.model';

@Component({
  selector: 'app-beneficiario-entidad',
  templateUrl: './beneficiarioEntidad.component.html',
  styleUrls: ['./beneficiarioEntidad.component.css']
})
export class BeneficiarioEntidadComponent implements OnInit, OnDestroy {
  @Input() entidadSeleccionada: Entidad;
  @ViewChild('scrollMe') scrollMe: ElementRef;


  public showContainer: boolean;

  constructor(
    private service: BeneficiarioService,
    public breakpointObserver: BreakpointObserver
  ) { }


  cols: any[];
  pageSize: number = 10;
  start: number = 0;
  
  block: boolean;
  
  departamento: string;
  distrito: string;
  totalRecords: number = 0;
  sortAsc: boolean = true;
  sortField: string;
  beneficiario: Beneficiario;
  selectedBeneficiario: Beneficiario;
  beneficiarios: Beneficiario[];
  //opcionesExtensionFile:any[];
  loading: boolean = true;
  loadingSpinner: boolean = true;
  filter: string;
  
  nombresApellidos: string;
  nroDocumento: string;
  tipoDocumento:string;
  nacionalidad:string;
  ocupacion:string;
  denominacion:string;
  rucNro:string;
  fechaInscripcion:string;
  capitalSuscrito:string;
  capitalIntegrado:string;
  fechaConstitucion:string;

  fechaBeneficiarioFinal:string;
  porcentajeParticipacion:number;
  porcentajeDerechoVoto:number;
  fechaActualizacion: Date;

  cadenaControl;
  informacionAdicional;
  condicion;
  fechaNacimiento;
  paisResidencia;
  direccion;

  @Output() bindVisible: EventEmitter<boolean> = new EventEmitter<boolean>(true);

  ngOnInit() {
    this.fechaActualizacion = new Date();
    if (this.fechaActualizacion.getHours() < 7) {
        this.fechaActualizacion.setDate(this.fechaActualizacion.getDate() - 1);
    }
    this.loading = true;
    this.loadingSpinner = false;
    this.beneficiario = new Beneficiario();

    this.breakpointObserver
      .observe(['(max-width:40em)'])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.showContainer = true;
        } else {
          this.showContainer = false;
        }
      });

    // this.renderer.setAttribute(this.elementRef.nativeElement, 'head-columns', 'ui-column-title');
    this.cols = [
      { field: 'nombres_apellidos', header: 'Nombres y Apellidos', width: '8%', sortField: 'nombres_apellidos' },
      { field: 'nro_documento', header: 'Nro de Documento', width: '6%', sortField: 'nro_documento' },
      { field: 'tipo_documento', header: 'Tipo de Documento', width: '6%', sortField: 'tipo_documento' },
      { field: 'nacionalidad', header: 'Nacionalidad', width: '6%', sortField: 'nacionalidad' },
      { field: 'ocupacion', header: 'Profesión', width: '8%', sortField: 'ocupacion' },
      { field: 'denominacion', header: 'Empresa', width: '8%', sortField: 'denominacion' },
      { field: 'ruc_nro', header: 'RUC', width: '6%', sortField: 'ruc_nro' },
      { field: 'cadena_control', header: 'Cadena de Control', width: '6%', sortField: 'cadena_control' },
      { field: 'informacion_adicional', header: 'Información Adicional', width: '6%', sortField: 'informacion_adicional' },
      { field: 'condicion', header: 'Condición', width: '4%', sortField: 'condicion' },
      { field: 'fecha_nacimiento', header: 'Fecha de Nacimiento', width: '7%', sortField: 'fecha_nacimiento' },
      { field: 'pais_residencia', header: 'País de Residencia', width: '6%', sortField: 'pais_residencia' },
      { field: 'calle', header: 'Dirección', width: '6%', sortField: 'calle' },
      { field: 'porcentaje_participacion', header: 'Porcentaje de Participación', width: '5%' },
      { field: 'porcentaje_derechovoto', header: 'Porcentaje de Derecho a Voto', width: '5%' },
      { field: 'fecha_beneficiario', header: 'Fecha de Beneficiario Final', width: '7%' }
    ];

    /*this.opcionesExtensionFile=[
      {value:null,label:"Sin Filtro"},
      {value:'pdf',label:"pdf"},
      {value:'otros',label:"Otros"}
    ];*/
  }

  ngOnDestroy() {
    this.service.disconnet();
  }

  
  gotoDrive() {
    window.open("https://drive.google.com/drive/folders/1Y7bO7WsqesKi67zczUup0m8HzL9BQE3_?usp=sharing", "_blank");
  }

  onRowSelect(event) {
    this.scrollMe.nativeElement.scrollIntoView();
  }

  onFilterChange($event, dt) {
    if ($event) {
      this.loadingSpinner = true;
      switch (dt) {
        case 'nombresApellidos':
          this.nombresApellidos = $event.target.value;
          break;
        case 'nroDocumento':
          this.nroDocumento = $event.target.value;
          break;
        case 'tipoDocumento':
          this.tipoDocumento = $event.target.value;
          break;
        case 'nacionalidad':
          this.nacionalidad = $event.target.value;
          break;
        case 'ocupacion':
            this.ocupacion = $event.target.value;
            break;
        case 'denominacion':
            this.denominacion = $event.target.value;
            break;
        case 'rucNro':
            this.rucNro = $event.target.value;
            break;
        case 'fechaInscripcion':
            this.fechaInscripcion = $event.target.value;
            break;
        case 'capitalSuscrito':
            this.capitalSuscrito = $event.target.value;
            break;
        case 'capitalIntegrado':
            this.capitalIntegrado = $event.target.value;
            break;
        case 'fechaConstitucion':
            this.fechaConstitucion = $event.target.value;
            break;

        case 'porcentajeParticipacion':
              this.porcentajeParticipacion = $event.target.value;
              break;
        case 'porcentajeDerechoVoto':
            this.porcentajeDerechoVoto = $event.target.value;
            break;
        case 'fecha_beneficiario':
            this.fechaBeneficiarioFinal = $event.target.value;
            break;

        case 'cadenaControl':
            this.cadenaControl = $event.target.value;
            break;
        case 'informacionAdicional':
            this.informacionAdicional = $event.target.value;
            break;
        case 'paisResidencia':
            this.paisResidencia = $event.target.value;
            break;
        case 'condicion':
            this.condicion = $event.target.value;
            break;
        case 'fechaNacimiento':
            this.fechaNacimiento = $event.target.value;
            break;
        case 'calle':
            this.direccion = $event.target.value;
            break;
        default:
      }
      this.loadServices();
    }
  }

  load($event: any) {
    this.loading = true;
    if ($event) {
      //this.filter = $event.globalFilter;
      this.start = $event.first;
      this.pageSize = $event.rows;
      this.sortField = $event.sortField;

      if ($event.sortOrder == 1)
        this.sortAsc = true;
      else
        this.sortAsc = false;
    }
    this.loadServices();
  }

  private loadServices() {
    this.service.getBeneficiariosEntidad(this.start, this.pageSize, this.sortAsc, this.sortField, 
      this.nombresApellidos, this.nroDocumento, this.tipoDocumento, this.nacionalidad, this.ocupacion, 
      this.porcentajeDerechoVoto, this.porcentajeParticipacion, this.fechaBeneficiarioFinal, this.denominacion, 
      this.rucNro, this.capitalSuscrito, this.capitalIntegrado,
      this.fechaConstitucion, this.entidadSeleccionada.ruc_nro)
      .subscribe(beneficiarios => {
        this.loading = false;
        this.beneficiarios = beneficiarios.lista;

        this.loadingSpinner = false;
        this.totalRecords = beneficiarios.totalRecords;
      });
  }

  close() {
    this.bindVisible.emit(false);
  }

  /*descargaBeneficiarioDownload(data: Beneficiario) {
    window.open("/rest/pytyvo/getFileDocument/"+data.id);
  }
  listadoPdf() {
    window.open("/rest/pytyvo/getAllQueryReport?"+this.service.getParamsString());
  }
  listadoXls() {
    window.open("/rest/pytyvo/getAllQueryReport?"+this.service.getParamsString()+"&type=xls");
  }
  listadoCsv() {
    window.open("/rest/pytyvo/getAllQueryReport?"+this.service.getParamsString()+"&type=csv");
  }*/

}
