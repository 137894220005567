import { Component, OnInit, EventEmitter, Output, OnDestroy, ViewChild, Renderer2, ElementRef, Input } from '@angular/core';
import { Beneficiario } from '../model/beneficiario.model';
import { BeneficiarioService } from '../beneficiario.service';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Entidad } from '../../entidad/model/entidad.model';

import { Router } from '@angular/router';

@Component({
  selector: 'app-beneficiario-publico',
  templateUrl: './beneficiario-publico.component.html',
  styleUrls: ['./beneficiario-publico.component.css']
})
export class BeneficiarioPublicoComponent implements OnInit, OnDestroy {
  @Input() entidadSeleccionada: Entidad;
  //@ViewChild('scrollMe') scrollMe: ElementRef;
  public showContainer: boolean;
  constructor(private service: BeneficiarioService, public breakpointObserver: BreakpointObserver, private router: Router) { }
  cols: any[];
  pageSize: number = 10;
  start: number = 0;
  block: boolean;
  departamento: string;
  distrito: string;
  totalRecords: number = 0;
  sortAsc: boolean = true;
  sortField: string;
  beneficiario: Beneficiario;
  //selectedBeneficiario: Beneficiario;
  beneficiarios: Beneficiario[];
  //opcionesExtensionFile:any[];
  loading: boolean = true;
  loadingSpinner: boolean = true;
  filter: string;
  
  nombresApellidos: string;
  nroDocumento: string;
  tipoDocumento:string;
  nacionalidad:string;
  ocupacion:string;
  denominacion:string;
  rucNro:string;
  fechaInscripcion:string;
  fechaConstitucion:string;

  fechaBeneficiarioFinal:string;
  porcentajeParticipacion:number;
  porcentajeDerechoVoto:number;
  fechaActualizacion: Date;

  selectedBeneficiarioTemp;
  selectedBeneficiario;

  cadenaControl;
  informacionAdicional;
  condicion;
  fechaNacimiento;
  paisResidencia;
  direccion;
  mensajeExport:string;

  @Output() bindVisible: EventEmitter<boolean> = new EventEmitter<boolean>(true);

  ngOnInit() {
    this.fechaActualizacion = new Date();
    if (this.fechaActualizacion.getHours() < 7) {
        this.fechaActualizacion.setDate(this.fechaActualizacion.getDate() - 1);
    }
    this.loading = true;
    this.loadingSpinner = false;
    this.beneficiario = new Beneficiario();

    this.breakpointObserver
      .observe(['(max-width:40em)'])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.showContainer = true;
        } else {
          this.showContainer = false;
        }
      });
    this.cols = [
      { field: 'denominacion', header: 'Empresa', width: '8%', sortField: 'denominacion' },
      { field: 'ruc_nro', header: 'RUC', width: '6%', sortField: 'ruc_nro' }
    ];
  }

  ngOnDestroy() {
    this.service.disconnet();
  }

  
 /* gotoDrive() {
    window.open("https://drive.google.com/drive/folders/1Y7bO7WsqesKi67zczUup0m8HzL9BQE3_?usp=sharing", "_blank");
  }

  onRowSelect(event) {
    this.scrollMe.nativeElement.scrollIntoView();
  }*/

  /*verEntidades(rowData){
    this.selectedBeneficiarioTemp = rowData;
    this.selectedBeneficiario = null;

    setTimeout(() => {
      this.selectedBeneficiario = this.selectedBeneficiarioTemp;
    }, 0);
    this.scrollMe.nativeElement.scrollIntoView({behavior: 'smooth'});
  }*/

  onFilterChange($event, dt) {
    if ($event) {
      this.loadingSpinner = true;
      switch (dt) {
        case 'denominacion':
            this.denominacion = $event.target.value;
            break;
        case 'rucNro':
            this.rucNro = $event.target.value;
            break;
        default:
      }
      this.loadServices();
    }
  }

  load($event: any) {
    this.loading = true;
    if ($event) {
      //this.filter = $event.globalFilter;
      this.start = $event.first;
      this.pageSize = $event.rows;
      this.sortField = $event.sortField;

      if ($event.sortOrder == 1)
        this.sortAsc = true;
      else
        this.sortAsc = false;
    }
    this.loadServices();
  }

  private loadServices() {
    this.pageSize=10;
    this.service.getBeneficiariosPublico(this.start, this.pageSize, this.sortAsc, this.sortField, 
       this.denominacion, this.rucNro)
      .subscribe(beneficiarios => {
        this.loading = false;
        this.beneficiarios = beneficiarios.lista;
        this.loadingSpinner = false;
        this.totalRecords = beneficiarios.totalRecords;
        if(this.totalRecords<1000)
        {
          this.mensajeExport="Lista filtrada en CSV";
        }
        else
        {
          this.mensajeExport="La búsqueda devuelve demasiados registros. Debe refinar más su búsqueda.";
        }
      }, error => {
        if(error.status == 401){
          this.loading = false;
          this.router.navigate(["/login"]);
        } 
      });
  }

  close() {
    this.bindVisible.emit(false);
  }

  csv(table:any)
  {
    this.pageSize=2147483647;
    this.service.getBeneficiariosPublico(this.start, this.pageSize, this.sortAsc, this.sortField, 
      this.denominacion, this.rucNro)
      .subscribe(beneficiarios => {
        this.loading = false;
        this.beneficiarios = beneficiarios.lista;
        this.loadingSpinner = false;
        this.totalRecords = beneficiarios.totalRecords;
        setTimeout(() => {
          table.exportCSV();
        }, 0);
      }, error => {
        if(error.status == 401){
          this.loading = false;
          this.router.navigate(["/login"]);
        } 
      });
  }

}
