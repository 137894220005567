import { Injectable } from '@angular/core';
import { HttpErrorHandler } from '../util/http.error.handler';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { finalize, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SociosService {

  private url = 'rest/socio';
  private handler: HttpErrorHandler = new HttpErrorHandler();
  private loading = new BehaviorSubject<boolean>(false);
  private params: HttpParams;

  constructor(private http: HttpClient) { }

  connect(): Observable<boolean> {
    return this.loading.asObservable();
  }

  disconnet(): void {
    this.loading.complete();
  }

  getEntidadSocios(start: number, pageSize: number, sortAsc: boolean, sortField: string, denominacion, nroDocumento, 
    tipoDocumento, profesion, cantidadAccionCuota, valorAccionCuota, categoriaAccionCuota, calle, rucEntidad): Observable<any> {
    this.loading.next(true);
    this.params = new HttpParams();

    if (sortField)
      this.params = this.params.set('sortField', sortField);

    this.params = this.params.set('start', start.toString()).set('pageSize', pageSize.toString()).set('sortAsc', sortAsc.toString());
    
    if (denominacion)
      this.params = this.params.set('denominacion', denominacion);
    
    if (nroDocumento)
      this.params = this.params.set('nroDocumento', nroDocumento);

    if (tipoDocumento)
      this.params = this.params.set('tipoDocumento', tipoDocumento);

    if (profesion)
      this.params = this.params.set('ocupacion', profesion);
    
    if (cantidadAccionCuota)
      this.params = this.params.set('cantidadAccionCuota', cantidadAccionCuota.toString());

    if (valorAccionCuota)
      this.params = this.params.set('valorAccionCuota', valorAccionCuota.toString());

    if (categoriaAccionCuota)
      this.params = this.params.set('categoriaAccionCuota', categoriaAccionCuota.toString());

    if (calle)
      this.params = this.params.set('direccion', calle);

    /*if (fechaComprobante)
      this.params = this.params.set('fechaComprobante', fechaComprobante.toString());

    if (tipoPresupuesto)
      this.params = this.params.set('tipoPresupuesto', tipoPresupuesto.toString());

    if (fuenteFinanciamiento)
      this.params = this.params.set('fuenteFinanciamiento', fuenteFinanciamiento);

    if (grupoGastoNombre)
      this.params = this.params.set('grupoGastoNombre', grupoGastoNombre);

    if (pagoMes)
      this.params = this.params.set('pagoMes', pagoMes);

    if (importe){
      let importeString = importe.toString().replace(/\./g,'');
      this.params = this.params.set('importe', importeString);
    } 
    if (asientoNro)
      this.params = this.params.set('asientoNro', asientoNro);
    if (fechaAsiento)
      this.params = this.params.set('fechaAsiento', fechaAsiento);
    
    if (fechaEgreso)
      this.params = this.params.set('fechaEgreso', fechaEgreso);
    if (resolucionNro)
      this.params = this.params.set('resolucionNro', resolucionNro);

    if (resolucionFecha)
      this.params = this.params.set('resolucionFecha', resolucionFecha);
    if (facturaNro)
      this.params = this.params.set('facturaNro', facturaNro);
    if (chequeNro)
      this.params = this.params.set('chequeNro', chequeNro);
    if (bancoNombre)
      this.params = this.params.set('bancoNombre', bancoNombre);
    if (codigoContratacion)
      this.params = this.params.set('codigoContratacion', codigoContratacion);
    if (ordenCompraNro)
      this.params = this.params.set('ordenCompraNro', ordenCompraNro);
    if (fechaCreacion)
      this.params = this.params.set('fechaCreacion', fechaCreacion);*/

    return this.http.get<any>(this.url + '/entidad/'+rucEntidad,  {params: this.params} );
      /*.pipe(finalize(() => { this.loading.next(false) }))
      .pipe(catchError(this.handler.handleError<any>('entidad', {})));*/
  }

  getHistoricoSocios(start: number, pageSize: number, sortAsc: boolean, sortField: string, denominacion, nroDocumento, 
    tipoDocumento, profesion, cantidadAccionCuota, valorAccionCuota, categoriaAccionCuota, calle, idEntidad): Observable<any> {
    this.loading.next(true);
    this.params = new HttpParams();

    if (sortField)
      this.params = this.params.set('sortField', sortField);

    this.params = this.params.set('start', start.toString()).set('pageSize', pageSize.toString()).set('sortAsc', sortAsc.toString());
    
    if (denominacion)
      this.params = this.params.set('denominacion', denominacion);
    
    if (nroDocumento)
      this.params = this.params.set('nroDocumento', nroDocumento);

    if (tipoDocumento)
      this.params = this.params.set('tipoDocumento', tipoDocumento);

    if (profesion)
      this.params = this.params.set('ocupacion', profesion);
    
    if (cantidadAccionCuota)
      this.params = this.params.set('cantidadAccionCuota', cantidadAccionCuota.toString());

    if (valorAccionCuota)
      this.params = this.params.set('valorAccionCuota', valorAccionCuota.toString());

    if (categoriaAccionCuota)
      this.params = this.params.set('categoriaAccionCuota', categoriaAccionCuota.toString());

    if (calle)
      this.params = this.params.set('direccion', calle);

    return this.http.get<any>(this.url + '/historico/'+idEntidad,  {params: this.params} );
      /*.pipe(finalize(() => { this.loading.next(false) }))
      .pipe(catchError(this.handler.handleError<any>('entidad', {})));*/
  }

  getParamsString():string {
    return this.params.toString();
  }

}
