import { Injectable } from '@angular/core';
import { HttpErrorHandler } from '../util/http.error.handler';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { HistoricoTable } from './historico-table.model';
import { finalize, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class HistoricoService {

  private url = 'rest/historico';
  private handler: HttpErrorHandler = new HttpErrorHandler();
  private loading = new BehaviorSubject<boolean>(false);
  private params: HttpParams;

  constructor(private http: HttpClient) { }

  connect(): Observable<boolean> {
    return this.loading.asObservable();
  }

  disconnet(): void {
    this.loading.complete();
  }

  getAllQueryHistorico(start: number, pageSize: number, sortAsc: boolean, sortField: string, ruc, denominacion:string, 
    fechaInscripcion, capitalSuscrito, capitalIntegrado, dictamenConstitucionFecha, origen:string): Observable<HistoricoTable> {
    this.loading.next(true);
    this.params = new HttpParams();
    if (sortField)
      this.params = this.params.set('sortField', sortField);
    /*if (ruc)
      this.params = this.params.set('ruc', ruc);*/
    if (denominacion)
      this.params = this.params.set('denominacion', denominacion);
    if (fechaInscripcion)
      this.params = this.params.set('fechaInscripcion', fechaInscripcion);
    if (capitalSuscrito)
      this.params = this.params.set('capitalSuscrito', capitalSuscrito);
    if (capitalIntegrado)
      this.params = this.params.set('capitalIntegrado', capitalIntegrado);

    if (dictamenConstitucionFecha)
      this.params = this.params.set('dictamenConstitucionFecha', dictamenConstitucionFecha);

    if (origen)
      this.params = this.params.set('origen', origen);
    /*if (totalPeriodoGasto){
      let totalPeriodoGastoString = totalPeriodoGasto.toString().replace(/\./g,'');
      this.params = this.params.set('totalPeriodoGasto', totalPeriodoGastoString);
    }
    if (totalPeriodoSaldoTransferencia){
      let totalPeriodoSaldoTransferenciaString = totalPeriodoSaldoTransferencia.toString().replace(/\./g,'');
      this.params = this.params.set('totalPeriodoSaldoTransferencia', totalPeriodoSaldoTransferenciaString);
    }

    if (fechaCreacion)
      this.params = this.params.set('fechaCreacion', fechaCreacion);*/

    this.params = this.params.set('start', start.toString()).set('pageSize', pageSize.toString()).set('sortAsc', sortAsc.toString());

    return this.http.get<HistoricoTable>(this.url + '/getAllQuery/'+ruc,  {params: this.params} )
      .pipe(finalize(() => { this.loading.next(false) }))
      .pipe(catchError(this.handler.handleError<HistoricoTable>('getAllQueryHistorico', new HistoricoTable()))
    );
  }

  getParamsString():string {
    return this.params.toString();
  }

}
