import { Injectable } from '@angular/core';
import { HttpErrorHandler } from '../util/http.error.handler';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BeneficiarioTable } from './shared/beneficiario-table.model';
import { finalize, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BeneficiarioService {

  private url = 'rest/beneficiario';
  private handler: HttpErrorHandler = new HttpErrorHandler();
  private loading = new BehaviorSubject<boolean>(false);
  private params: HttpParams;

  constructor(private http: HttpClient) { }

  connect(): Observable<boolean> {
    return this.loading.asObservable();
  }

  disconnet(): void {
    this.loading.complete();
  }
  
  getAllQueryBeneficiario(start: number, pageSize: number, sortAsc: boolean, sortField: string, nombreApellido:string, nroDocumento:string, 
    tipoDocumento:string, nacionalidad:string, ocupacion:string, porcentajeDerechoVoto:number, porcentajeParticipacion:number, 
    fechaBeneficiarioFinal:string, denominacion:string, rucNro:string,
    fechaInscripcion:string, fechaConstitucion:string, cadenaControl, informacionAdicional,
    condicion, fechaNacimiento, paisResidencia, direccion): Observable<BeneficiarioTable> {
    this.loading.next(true);
    this.params = new HttpParams();
    if (sortField)
      this.params = this.params.set('sortField', sortField);
    if (nombreApellido)
      this.params = this.params.set('nombresApellidos', nombreApellido);
    if (nroDocumento)
      this.params = this.params.set('nroDocumento', nroDocumento);
      if (tipoDocumento)
      this.params = this.params.set('tipoDocumento', tipoDocumento);
    if (nacionalidad)
      this.params = this.params.set('nacionalidad', nacionalidad);
    if (ocupacion)
      this.params = this.params.set('profesion', ocupacion);

    if (porcentajeParticipacion)
      this.params = this.params.set('porcentajeParticipacion', porcentajeParticipacion.toString());
    if (porcentajeDerechoVoto)
      this.params = this.params.set('porcentajeDerechoVoto', porcentajeDerechoVoto.toString());
    if (fechaBeneficiarioFinal)
      this.params = this.params.set('fechaBeneficiarioFinal', fechaBeneficiarioFinal);

    if (denominacion)
      this.params = this.params.set('denominacion', denominacion);
    if (rucNro)
      this.params = this.params.set('rucNro', rucNro);
    if (fechaInscripcion)
      this.params = this.params.set('fechaInscripcion', fechaInscripcion);
    if (fechaConstitucion)
      this.params = this.params.set('fechaConstitucion', fechaConstitucion);

    if (cadenaControl)
      this.params = this.params.set('cadenaControl', cadenaControl);

    if (informacionAdicional)
      this.params = this.params.set('informacionAdicional', informacionAdicional);

    if (condicion)
      this.params = this.params.set('condicion', condicion);

    if (fechaNacimiento)
      this.params = this.params.set('fechaNacimiento', fechaNacimiento);
    if (paisResidencia)
      this.params = this.params.set('paisResidencia', paisResidencia);
    if (direccion)
      this.params = this.params.set('direccion', direccion);

    this.params = this.params.set('start', start.toString()).set('pageSize', pageSize.toString()).set('sortAsc', sortAsc.toString());

    return this.http.get<BeneficiarioTable>(this.url + '/getAllQuery',  {params: this.params} );
      /*.pipe(finalize(() => { this.loading.next(false) }))
      .pipe(catchError(this.handler.handleError<BeneficiarioTable>('getAllQueryBeneficiario', new BeneficiarioTable())));*/
  }
  
  getBeneficiariosPublico(start: number, pageSize: number, sortAsc: boolean, sortField: string, 
    denominacion:string, rucNro:string): Observable<BeneficiarioTable> {
    this.loading.next(true);
    this.params = new HttpParams();
    if (sortField)
      this.params = this.params.set('sortField', sortField);
    if (denominacion)
      this.params = this.params.set('denominacion', denominacion);
    if (rucNro)
      this.params = this.params.set('rucNro', rucNro);
    
    this.params = this.params.set('start', start.toString()).set('pageSize', pageSize.toString()).set('sortAsc', sortAsc.toString());

    return this.http.get<BeneficiarioTable>(this.url + '/getListaPublica',  {params: this.params} );
  }

  getHistoricoBeneficiarios(start: number, pageSize: number, sortAsc: boolean, sortField: string, nombresApellidos,
    nroDocumento, tipoDocumento, nacionalidad, ocupacion, denominacion, ruc, dictamenConstitucionFecha,
    porcentajeParticipacion, porcentajeDerechoVoto, fechaBeneficiarioFinal, cadenaControl, informacionAdicional,
    condicion, fechaNacimiento, paisResidencia, direccion, idEntidad): Observable<BeneficiarioTable> {
    this.loading.next(true);

    this.params = new HttpParams();
    if (sortField)
      this.params = this.params.set('sortField', sortField);
    
    if (nombresApellidos)
      this.params = this.params.set('nombresApellidos', nombresApellidos);
    if (denominacion)
      this.params = this.params.set('denominacion', denominacion);
    if (ruc)
      this.params = this.params.set('ruc', ruc);
    if (nroDocumento)
      this.params = this.params.set('nroDocumento', nroDocumento);
    if (tipoDocumento)
      this.params = this.params.set('tipoDocumento', tipoDocumento);
    if (nacionalidad)
      this.params = this.params.set('nacionalidad', nacionalidad);
    if (ocupacion)
      this.params = this.params.set('profesion', ocupacion);
    
    if (porcentajeParticipacion)
      this.params = this.params.set('porcentajeParticipacion', porcentajeParticipacion);
    if (porcentajeDerechoVoto)
      this.params = this.params.set('porcentajeDerechoVoto', porcentajeDerechoVoto);

    if (dictamenConstitucionFecha)
      this.params = this.params.set('dictamenConstitucionFecha', dictamenConstitucionFecha);

    if (fechaBeneficiarioFinal)
      this.params = this.params.set('fechaBeneficiarioFinal', fechaBeneficiarioFinal);
    
    if (cadenaControl)
      this.params = this.params.set('cadenaControl', cadenaControl);
    if (informacionAdicional)
      this.params = this.params.set('informacionAdicional', informacionAdicional);
    if (condicion)
      this.params = this.params.set('condicion', condicion);
    if (fechaNacimiento)
      this.params = this.params.set('fechaNacimiento', fechaNacimiento);
    if (paisResidencia)
      this.params = this.params.set('paisResidencia', paisResidencia);
    if (direccion)
      this.params = this.params.set('direccion', direccion);

    this.params = this.params.set('start', start.toString()).set('pageSize', pageSize.toString()).set('sortAsc', sortAsc.toString());

    return this.http.get<BeneficiarioTable>(this.url + '/historico/'+idEntidad,  {params: this.params} )
      .pipe(finalize(() => { this.loading.next(false) }))
      .pipe(catchError(this.handler.handleError<BeneficiarioTable>('historico', new BeneficiarioTable()))
    );
  }

  getBeneficiariosEntidad(start: number, pageSize: number, sortAsc: boolean, sortField: string, nombresApellidos,
    nroDocumento, tipoDocumento, nacionalidad, ocupacion, denominacion, ruc, capitalSuscrito, capitalIntegrado,
    dictamenConstitucionFecha, porcentajeParticipacion, porcentajeDerechoVoto, fechaBeneficiarioFinal, rucEntidad): Observable<BeneficiarioTable> {
    this.loading.next(true);

    this.params = new HttpParams();
    if (sortField)
      this.params = this.params.set('sortField', sortField);

    if (nombresApellidos)
      this.params = this.params.set('nombresApellidos', nombresApellidos);
    if (nroDocumento)
      this.params = this.params.set('nroDocumento', nroDocumento);
    if (tipoDocumento)
      this.params = this.params.set('tipoDocumento', tipoDocumento);
    if (nacionalidad)
      this.params = this.params.set('nacionalidad', nacionalidad);
    if (ocupacion)
      this.params = this.params.set('ocupacion', ocupacion);
    if (denominacion)
      this.params = this.params.set('denominacion', denominacion);
    if (ruc)
      this.params = this.params.set('ruc', ruc);

    if (capitalSuscrito)
      this.params = this.params.set('capitalSuscrito', capitalSuscrito);
    if (capitalIntegrado)
      this.params = this.params.set('capitalIntegrado', capitalIntegrado);
    
    if (porcentajeParticipacion)
      this.params = this.params.set('porcentajeParticipacion', porcentajeParticipacion);
    if (porcentajeDerechoVoto)
      this.params = this.params.set('porcentajeDerechoVoto', porcentajeDerechoVoto);

    if (dictamenConstitucionFecha)
      this.params = this.params.set('dictamenConstitucionFecha', dictamenConstitucionFecha);

    if (fechaBeneficiarioFinal)
      this.params = this.params.set('fechaBeneficiarioFinal', fechaBeneficiarioFinal);

    this.params = this.params.set('start', start.toString()).set('pageSize', pageSize.toString()).set('sortAsc', sortAsc.toString());

    return this.http.get<BeneficiarioTable>(this.url + '/entidad/'+rucEntidad,  {params: this.params} )
      .pipe(finalize(() => { this.loading.next(false) }))
      .pipe(catchError(this.handler.handleError<BeneficiarioTable>('entidad', new BeneficiarioTable()))
    );
  }

  getParamsString():string {
    return this.params.toString();
  }

}
