import { Component, Inject, forwardRef } from '@angular/core';

@Component({
    selector: 'app-footer',
    template: `
        <div class="layout-footer">
        <!--<div>
            <p style="text-align:justify;font-style:italic;font-weight:bold;">Los datos publicados en este portal son obtenidos de la información 
                registrada por cada Organismo o Entidad del Estado (OEE) ante la Dirección General de Personas y Estructuras Jurídicas y Beneficiarios Finales (DGPEJBF). 
                Constituye una recopilación fiel e integral de la información registrada, 
                sin agregados, supresiones o correcciones de ninguna naturaleza. La veracidad o contenido de los datos son responsabilidad exclusiva de
                 los OEE y están sujetos a verificación por parte de las Entidades.</p>
            </div>-->
            <span class="footer-text-left">
                <img alt="Datos abiertos" src="assets/layout/images/datos-abiertos.png" style="width:170px; margin-bottom:0px;">
                <img alt="Star" src="assets/layout/images/star.png" style="width:12px;padding-bottom:10px;">
                <img alt="Star" src="assets/layout/images/star.png" style="width:12px;padding-bottom:10px;">
                <img alt="Star" src="assets/layout/images/star.png" style="width:12px;padding-bottom:10px;">
                
                <br/>
                <a rel="license" [routerLink]="['/licencia']" style="text-decoration:underline;" title="Ir a la Licencia">
                    Licencia de Uso de la Información Pública - Decreto 4064 - Ley Nro. 5282/2014
                </a>
            </span>
            
            <span class="footer-text-right">
                <a href="https://www.mitic.gov.py" style=""><img src="https://cdn.paraguay.gov.py/marca-SENATICs/marca-senatics-desarrollado-por.png" style="height:30px !important; width:auto !important;" alt="Marca producto/servicio MITIC"></a>
            </span>
        </div>
    `
})
export class AppFooterComponent {

}
