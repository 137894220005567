import { Component, OnInit, EventEmitter, Output, OnDestroy, ViewChild, Renderer2, ElementRef } from '@angular/core';
import { EntidadService } from '../entidad/entidad.service';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Entidad } from './model/entidad.model';
import { Socio } from '../socios/model/socio.model';

import { Router } from '@angular/router';

@Component({
  selector: 'app-entidad',
  templateUrl: './entidad.component.html'
  //styleUrls: ['./emergencia-rendicion.component.css']
})
export class EntidadComponent implements OnInit, OnDestroy {
  @ViewChild('scrollMe') scrollMe: ElementRef;
  @ViewChild('scrollHistorico') scrollHistorico: ElementRef;


  public showContainer: boolean;

  constructor(private service: EntidadService, public breakpointObserver: BreakpointObserver, private router: Router) { }

  fechaActualizacion: Date;
  cols: any[];
  pageSize: number = 10;
  start: number = 0;
  ejercicioFiscal: number;
  block: boolean;
  entidad: string;
  nroRectificada: number;
  id: number;
  periodoDesde: string;
  periodoHasta: string;
  totalPeriodoGasto: number;
  totalPeriodoTransferencia: number;
  totalPeriodoSaldoTransferencia: number;
  nombreOrdenador: string;
  cedulaOrdenador: string;
  nombreDirector: string;
  cedulaDirector: string;
  fechaCreacion: string;
  totalRecords: number = 0;
  sortAsc: boolean = true;
  sortField: string;

  selectedVerSocios: any;

  // opcionesExtensionFile:any[];
  loading: boolean = true;
  loadingSpinner: boolean = true;
  filter: string;
  entidades: Entidad[];

  selectedSocioEnti: Socio;
  selectedSocioEntiTemp: Socio;

  selectedDirectivo: Socio;
  selectedDirectivoTemp: Socio;
  
  selectedBeneficiarioEnti;
  selectedBeneficiarioEntiTemp;

  selectedHistorico: Socio;
  selectedHistoricoTemp: Socio;

  ruc:string;
  denominacion:string;
  fechaInscripcion;
  capitalSuscrito;
  capitalIntegrado;
  dictamenConstitucionFecha;
  mensajeExport:string;

  @Output() bindVisible: EventEmitter<boolean> = new EventEmitter<boolean>(true);

  ngOnInit() {
    this.loading = true;
    this.loadingSpinner = false;
    this.fechaActualizacion = new Date();
    if (this.fechaActualizacion.getHours() < 7) {
        this.fechaActualizacion.setDate(this.fechaActualizacion.getDate() - 1);
    }

    this.breakpointObserver
      .observe(['(max-width:40em)'])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.showContainer = true;
        } else {
          this.showContainer = false;
        }
      });

    // this.renderer.setAttribute(this.elementRef.nativeElement, 'head-columns', 'ui-column-title');
    this.cols = [
      /*{ field: 'id', header: 'ID', width: '8%', sortField:'id' },
      { field: 'entidad', header: 'Entidad', width: '8%', sortField:'e.nombre' },
      { field: 'periodo_desde', header: 'Desde', width: '8%', sortField:'periodo_desde_sort' },
      { field: 'periodo_hasta', header: 'Hasta', width: '8%', sortField:'periodo_hasta_sort' },
      { field: 'total_periodo_transferencia', header: 'Total Transferencia (Gs.)', width: '8%', sortField:'total_periodo_transferencia_sort' },
      { field: 'total_periodo_saldo_transferencia', header: 'Total Saldo Anterior (Gs.)', width: '8%', sortField:'total_periodo_saldo_transferencia_sort' },
      { field: 'total_periodo_gasto', header: 'Total Gasto (Gs.)', width: '8%', sortField:'total_periodo_gasto_sort' },
      { field: 'nombre_director', header: 'Nombre Director', width: '8%', sortField:'nombre_director' },
      { field: 'cedula_director', header: 'Cédula Director', width: '8%', sortField:'cedula_director_sort' },
      { field: 'fecha_creacion', header: 'Fecha Rendición', width: '8%', sortField:'fecha_creacion_sort'}*/
      
      //Grilla empresas
      { field: 'ruc_nro', header: 'RUC', width: '15%', sortField:'ruc_nro' },
      { field: 'denominacion', header: 'Denominación', width: '25%', sortField:'denominacion' },
      //{ field: 'fecha_inscripcion', header: 'Fecha Inscripción', width: '10%', sortField:'fecha_inscripcion' },
      { field: 'capital_suscrito', header: 'Capital Suscrito (Gs.)', width: '15%', sortField:'capital_suscrito_sort' },
      { field: 'capital_integrado', header: 'Capital Integrado (Gs.)', width: '15%', sortField:'capital_integrado_sort' },
      { field: 'dictamen_constitucion_fecha', header: 'Fecha de Dictamen Constitución', width: '20%', sortField:'dictamen_constitucion_fecha' }
    ];

    /*this.opcionesExtensionFile=[
      {value:null,label:"Sin Filtro"},
      {value:'pdf',label:"pdf"},
      {value:'otros',label:"Otros"}
    ];*/
  }

  ngOnDestroy() {
    this.service.disconnet();
  }


  openCsvFullLink() {
    window.open('https://drive.google.com/drive/folders/1Y7bO7WsqesKi67zczUup0m8HzL9BQE3_?usp=sharing',  '_blank');
  }

  verHistorico(rowData){
    this.selectedHistoricoTemp = rowData;
    this.selectedHistorico = null;

    //Para ocultar las otras grillas
    this.selectedDirectivo = null;
    this.selectedBeneficiarioEnti = null;
    this.selectedSocioEnti = null;

    setTimeout(() => {
      this.selectedHistorico = this.selectedHistoricoTemp;
    }, 0);
    this.scrollHistorico.nativeElement.scrollIntoView({behavior: 'smooth'});
  }

  verDirectivos(rowData){
    this.selectedDirectivoTemp = rowData;
    this.selectedDirectivo = null;

    //Para ocultar las otras grillas
    this.selectedHistorico = null;
    this.selectedBeneficiarioEnti = null;
    this.selectedSocioEnti = null;

    setTimeout(() => {
      this.selectedDirectivo = this.selectedDirectivoTemp;
    }, 0);
    this.scrollMe.nativeElement.scrollIntoView({behavior: 'smooth'});
  }

  verSocios(rowData){
    this.selectedSocioEntiTemp = rowData;
    this.selectedSocioEnti = null;

    //Para ocultar las otras grillas
    this.selectedHistorico = null;
    this.selectedDirectivo = null;
    this.selectedBeneficiarioEnti = null;

    setTimeout(() => {
      this.selectedSocioEnti = this.selectedSocioEntiTemp;
    }, 0);
    this.scrollMe.nativeElement.scrollIntoView({behavior: 'smooth'});
  }

  verBeneficiarios(rowData){
    this.selectedBeneficiarioEntiTemp = rowData;
    this.selectedBeneficiarioEnti = null;

    //Para ocultar las otras grillas
    this.selectedHistorico = null;
    this.selectedDirectivo = null;
    this.selectedSocioEnti = null;

    setTimeout(() => {
      this.selectedBeneficiarioEnti = this.selectedBeneficiarioEntiTemp;
    }, 0);
    this.scrollMe.nativeElement.scrollIntoView({behavior: 'smooth'});
  }

  onFilterChange($event, dt) {
    if ($event) {

      this.loadingSpinner = true;
      this.start = 0;
      switch (dt) {
        case 'ruc':
          this.ruc = $event.target.value;
          break;
        case 'denominacion':
          this.denominacion = $event.target.value;
          break;
        case 'fechaInscripcion':
            this.fechaInscripcion = $event.target.value;
            break;
        case 'capitalSuscrito':
              this.capitalSuscrito = $event.target.value;
              break;
        case 'capitalIntegrado':
              this.capitalIntegrado = $event.target.value;
              break;
        case 'dictamenConstitucionFecha':
              this.dictamenConstitucionFecha = $event.target.value;
              break;
        case 'totalPeriodoTransferencia':
          this.totalPeriodoTransferencia = $event.target.value;
          break;
        case 'totalPeriodoSaldoTransferencia':
          this.totalPeriodoSaldoTransferencia = $event.target.value;
          break;
        case 'cedulaOrdenador':
              this.cedulaOrdenador = $event.target.value;
              break;
        case 'nombreDirector':
                this.nombreDirector = $event.target.value;
                break;
        case 'cedulaDirector':
                  this.cedulaDirector = $event.target.value;
                  break;
        
        default:
      }
      this.loadEntidades();
    }
  }

  load($event: any) {
    this.loading = true;
    if ($event) {
      // this.filter = $event.globalFilter;
      this.start = $event.first;
      this.pageSize = $event.rows;
      this.sortField = $event.sortField;

      if ($event.sortOrder == 1)
        this.sortAsc = true;
      else
        this.sortAsc = false;
    }
    this.loadEntidades();
  }

  private loadEntidades() {
    this.pageSize=10;
    this.service.getAllQueryEntidad(this.start, this.pageSize, this.sortAsc, this.sortField, this.ruc, 
      this.denominacion, this.fechaInscripcion, this.capitalSuscrito, this.capitalIntegrado, this.dictamenConstitucionFecha).subscribe(entidades => {
        this.loading = false;
        this.entidades = entidades.lista;
        this.loadingSpinner = false;
        this.totalRecords = entidades.totalRecords;
        if(this.totalRecords<1000)
        {
          this.mensajeExport="Lista filtrada en CSV";
        }
        else
        {
          this.mensajeExport="La búsqueda devuelve demasiados registros. Debe refinar más su búsqueda.";
        }
      }, error => {
        if(error.status == 401){
          this.loading = false;
          this.router.navigate(["/login"]);
        }
      });
  }

  close() {
    this.bindVisible.emit(false);
  }

  /*descargaBeneficiarioDownload(data: Beneficiario) {
    window.open("/rest/pytyvo/getFileDocument/"+data.id);
  }

  listadoPdf() {
    window.open("/rest/pytyvo/getAllQueryReport?"+this.service.getParamsString());
  }

  listadoXls() {
    window.open("/rest/pytyvo/getAllQueryReport?"+this.service.getParamsString()+"&type=xls");
  }

  listadoCsv() {
    window.open("/rest/pytyvo/getAllQueryReport?"+this.service.getParamsString()+"&type=csv");
  }*/

  csv(table:any)
  {
    this.pageSize=2147483647;
    this.service.getAllQueryEntidad(this.start, this.pageSize, this.sortAsc, this.sortField, this.ruc, 
      this.denominacion, this.fechaInscripcion, this.capitalSuscrito, this.capitalIntegrado, this.dictamenConstitucionFecha).subscribe(entidades => {
        this.loading = false;
        this.entidades = entidades.lista;
        this.loadingSpinner = false;
        this.totalRecords = entidades.totalRecords;
        setTimeout(() => {
          table.exportCSV();
        }, 0);
      }, error => {
        if(error.status == 401){
          this.loading = false;
          this.router.navigate(["/login"]);
        }
      });
  }

}
