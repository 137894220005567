import { Component, OnInit } from '@angular/core';
import { TipoEntidadService } from './tipoEntidad.service';
import { EstadisticasService } from './estadisticas.service';
import { Router } from '@angular/router';
import { StorageManagerService } from '../login/shared/storage-manager.service';

@Component({
  selector: 'app-portada',
  templateUrl: './portada.component.html',
})
export class PortadaComponent implements OnInit{
  tipoEntidadList:any[];
  data: any;
  estadisticas:any[];
  nuevasEntidadesFechaDesdeCantidad:string;
  nuevasEntidadesFechaDesde:string;
  beneficiariosFinalesCantidad:string;
  options:any;
  //esPaginaPublica:boolean = false;


  constructor(
    private tipoEntidadService: TipoEntidadService, private storageManager: StorageManagerService, private estadisticasService: EstadisticasService, private router: Router) { }
  
    ngOnInit() {

      //let token = this.storageManager.getLoginData().token;

     // if(this.storageManager.getLoginData()) {
        //this.esPaginaPublica = false;
        this.tipoEntidadService.getList().subscribe(tipoEntidadList => {
            this.tipoEntidadList = tipoEntidadList;
            let labels=[];
            let counts=[];
            let countOtros=0;
            let sum=0;
            for(let tipoEntidad of this.tipoEntidadList)
            {
              if(labels.length<3)
              {
                labels.push(tipoEntidad.nombre);
                counts.push(tipoEntidad.cantidad);
                sum+=tipoEntidad.cantidad;
              }
              else if(labels.length==3)
              {
                labels.push('Otros');
                countOtros+=tipoEntidad.cantidad;
              }
              else
              {
                countOtros+=tipoEntidad.cantidad;
              }
            }
            counts.push(countOtros);
            sum+=countOtros;
            this.data = {
              labels: labels,
              datasets: [
                  {
                      data: counts,
                      backgroundColor: [
                        'rgb(31, 119, 180)',
  'rgb(174, 199, 232)',
  'rgb(255, 127, 14)',
  'rgb(255, 187, 120)',
  'rgb(44, 160, 44)',
  'rgb(152, 223, 138)',
  'rgb(214, 39, 40)',
  'rgb(255, 152, 150)',
  'rgb(148, 103, 189)',
  'rgb(197, 176, 213)',
  'rgb(140, 86, 75)',
  'rgb(196, 156, 148)',
  'rgb(227, 119, 194)',
  'rgb(247, 182, 210)',
  'rgb(127, 127, 127)',
  'rgb(199, 199, 199)',
  'rgb(188, 189, 34)',
  'rgb(219, 219, 141)',
  'rgb(23, 190, 207)',
  'rgb(158, 218, 229)'
                      ]
                  }]
              };
              this.options={
                tooltips:{
                  callbacks:{
                    label:(toolTipArray,data)=>{return data.labels[toolTipArray.index]+': '+data.datasets[0].data[toolTipArray.index]+' ('+(Math.round(data.datasets[0].data[toolTipArray.index]*10000/sum)/100)+'%)'}
                  }
                }
              }
          }, error => {
            if(error.status == 401){
              this.router.navigate(["/login"]);
            } 
          });
          this.estadisticasService.getList().subscribe(estadisticas => {
            this.estadisticas = estadisticas;
            for(let estadistica of estadisticas)
            {
              if(estadistica.nombreVariable=='abt.datosAbiertos.estadisticas.nuevasEntidadesFechaDesde')
              {
                this.nuevasEntidadesFechaDesde=estadistica.valorVariable;
              }
              else if(estadistica.nombreVariable=='abt.datosAbiertos.estadisticas.nuevasEntidadesFechaDesdeCantidad')
              {
                this.nuevasEntidadesFechaDesdeCantidad=estadistica.valorVariable;
              }
              else if(estadistica.nombreVariable=='abt.datosAbiertos.estadisticas.beneficiariosFinalesCantidad')
              {
                this.beneficiariosFinalesCantidad=estadistica.valorVariable;
              }
            }
          }, error => {
            if(error.status == 401){
              this.router.navigate(["/login"]);
            } 
          });
       /* } else {
          this.esPaginaPublica = true;
        }*/
    }
}
