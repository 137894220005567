import { Routes, RouterModule } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';
import { AppMainComponent } from './app.main.component';
import { AppNotfoundComponent } from './pages/app.notfound.component';
import { AppErrorComponent } from './pages/app.error.component';
import { AppAccessdeniedComponent } from './pages/app.accessdenied.component';
import { AppLoginComponent } from './pages/app.login.component';
import { LicenciaComponent } from './pages/licencia.component';
import { AcercaComponent } from './pages/acerca.component';
import { AvisoComponent } from './pages/aviso.component';
import { AppInvoiceComponent } from './pages/app.invoice.component';
import { PoliticaComponent } from './pages/politica.component';
import { DatosComponent } from './pages/datos.component';
import { PortadaComponent } from './pages/portada.component';
import { AppHelpComponent } from './pages/app.help.component';
import { AppWizardComponent } from './pages/app.wizard.component';
import { DiccionarioGastoComponent } from './diccionario/gastos/diccionario-gasto.component';
import { EntidadComponent } from './entidad/entidad.component';
import { EntidadPublicoComponent } from './entidad/entidad-publico/entidad-publico.component';
import { MarcoComponent } from './pages/marco.component';
import { LoginComponent } from './login/login.component';
import { BeneficiarioComponent } from './beneficiario/beneficiario.component';
import { SessionGuardService } from './util/session-guard.service';
import { DiccionarioEntidadComponent } from './diccionario/entidad/diccionario-entidad.component';
import { DiccionarioBeneficiarioComponent } from './diccionario/beneficiario/diccionario-beneficiario.component';
import { DiccionarioDirectivoComponent } from './diccionario/directivo/diccionario-directivo.component';
import { DiccionarioSocioComponent } from './diccionario/socio/diccionario-socio.component';
import { BeneficiarioPublicoComponent } from './beneficiario/beneficiario-publico/beneficiario-publico.component';
import { GenerarClaveComponent } from './login/generar-clave/generar-clave.component';

export const routes: Routes = [
    { path: '', component: AppMainComponent,
        children: [
            { path: '', component: PortadaComponent },
//            { path: 'portada', component: PortadaComponent, canActivate: [SessionGuardService] },
            //{ path: '', redirectTo: '/portada', pathMatch: 'full'},
            { path: 'portada', component: PortadaComponent },
            { path: 'entidad', component: EntidadComponent, canActivate: [SessionGuardService] },
            { path: 'entidad-publico', component: EntidadPublicoComponent},
            { path: 'beneficiario-publico', component: BeneficiarioPublicoComponent},
            { path: 'datos', component: DatosComponent },
            { path: 'diccionarioentidad', component: DiccionarioEntidadComponent},
            { path: 'diccionariobeneficiario', component: DiccionarioBeneficiarioComponent},
            { path: 'diccionariodirectivo', component: DiccionarioDirectivoComponent, canActivate: [SessionGuardService] },
            { path: 'diccionariosocio', component: DiccionarioSocioComponent, canActivate: [SessionGuardService] },
            { path: 'licencia', component: LicenciaComponent },
            { path: 'politica', component: PoliticaComponent },
            { path: 'acerca', component: AcercaComponent},
            { path: 'aviso', component: AvisoComponent },
            { path: 'pages/invoice', component: AppInvoiceComponent },
            { path: 'pages/help', component: AppHelpComponent },
            { path: 'notfound', component: AppNotfoundComponent },
            { path: 'marco', component: MarcoComponent },
            { path: 'login', component: LoginComponent },
            { path: 'generarClave', component: GenerarClaveComponent },
            { path: 'beneficiario', component: BeneficiarioComponent, canActivate: [SessionGuardService] }
        ]
    },
    { path: 'error', component: AppErrorComponent },
    { path: 'accessdenied', component: AppAccessdeniedComponent },
    { path: 'login', component: AppLoginComponent },
    { path: 'wizard', component: AppWizardComponent },
    {path: 'portada', component: PortadaComponent},
    { path: '**', redirectTo: '/portada' },

];

export const AppRoutes: ModuleWithProviders = RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'});
