import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-diccionario',
  templateUrl: './diccionario.component.html',
  styleUrls: ['./diccionario.component.css']
})
export class DiccionarioComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  gotoDrive() {
    window.open("https://drive.google.com/drive/folders/1Y7bO7WsqesKi67zczUup0m8HzL9BQE3_?usp=sharing", "_blank");
  }
}
