import { Component, OnInit } from '@angular/core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { DiccionarioService } from '../diccionario.service';

import { Router } from '@angular/router';

@Component({
  selector: 'app-diccionario-directivo',
  templateUrl: './diccionario-directivo.component.html',
  styleUrls: ['./diccionario-directivo.component.css']
})
export class DiccionarioDirectivoComponent implements OnInit {
  diccionario: any;
  public showContainer: boolean;

  constructor(public breakpointObserver: BreakpointObserver, private diccionarioService: DiccionarioService, private router: Router) { }

  ngOnInit(): void {
    this.diccionarioService.getDiccionarioDirectivo().subscribe(data => {
      this.diccionario = data;
    }, error => {
      if(error.status == 401){
        //this.loading = false;
        this.router.navigate(["/login"]);
      } 
    });

    this.breakpointObserver
      .observe(['(max-width:40em)'])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.showContainer = true;
        } else {
          this.showContainer = false;
        }
      });
  }

  openCsvDirectivos() {
    window.open('assets/docs/directivo.csv', '_blank');
  }

}
