export class Beneficiario {
    id: string;
    nombres_apellidos: string;
    nro_documento: string;
    tipo_documento: string;
    nacionalidad: string;
    ocupacion: string;
    porcentajeParticipacion: number;
    porcentajeDerechoVoto: number;
    fecha_beneficiario: string;
    denominacion:string;
    ruc_nro:string;

    fecha_inscripcion: string;
    capital_suscrito:string;
    capital_integrado:string;
    dictamen_constitucion_fecha: string;
}
