import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HashLocationStrategy, LocationStrategy} from '@angular/common';
import {AppRoutes} from './app.routes';

import {AccordionModule} from 'primeng/accordion';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {BreadcrumbModule} from 'primeng/breadcrumb';
import {ButtonModule} from 'primeng/button';
import {CalendarModule} from 'primeng/calendar';
import {CardModule} from 'primeng/card';
import {CarouselModule} from 'primeng/carousel';
import {ChartModule} from 'primeng/chart';
import {CheckboxModule} from 'primeng/checkbox';
import {ChipsModule} from 'primeng/chips';
import {CodeHighlighterModule} from 'primeng/codehighlighter';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {ColorPickerModule} from 'primeng/colorpicker';
import {ContextMenuModule} from 'primeng/contextmenu';
import {DataViewModule} from 'primeng/dataview';
import {DialogModule} from 'primeng/dialog';
import {DropdownModule} from 'primeng/dropdown';
import {EditorModule} from 'primeng/editor';
import {FieldsetModule} from 'primeng/fieldset';
import {FileUploadModule} from 'primeng/fileupload';
import {FullCalendarModule} from 'primeng/fullcalendar';
import {GalleriaModule} from 'primeng/galleria';
import {InplaceModule} from 'primeng/inplace';
import {InputMaskModule} from 'primeng/inputmask';
import {InputSwitchModule} from 'primeng/inputswitch';
import {InputTextModule} from 'primeng/inputtext';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {LightboxModule} from 'primeng/lightbox';
import {ListboxModule} from 'primeng/listbox';
import {MegaMenuModule} from 'primeng/megamenu';
import {MenuModule} from 'primeng/menu';
import {MenubarModule} from 'primeng/menubar';
import {MessagesModule} from 'primeng/messages';
import {MessageModule} from 'primeng/message';
import {MultiSelectModule} from 'primeng/multiselect';
import {OrderListModule} from 'primeng/orderlist';
import {OrganizationChartModule} from 'primeng/organizationchart';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {PaginatorModule} from 'primeng/paginator';
import {PanelModule} from 'primeng/panel';
import {PanelMenuModule} from 'primeng/panelmenu';
import {PasswordModule} from 'primeng/password';
import {PickListModule} from 'primeng/picklist';
import {ProgressBarModule} from 'primeng/progressbar';
import {RadioButtonModule} from 'primeng/radiobutton';
import {RatingModule} from 'primeng/rating';
import {ScrollPanelModule} from 'primeng/scrollpanel';
import {SelectButtonModule} from 'primeng/selectbutton';
import {SlideMenuModule} from 'primeng/slidemenu';
import {SliderModule} from 'primeng/slider';
import {SpinnerModule} from 'primeng/spinner';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import {SplitButtonModule} from 'primeng/splitbutton';
import {StepsModule} from 'primeng/steps';
import {TabMenuModule} from 'primeng/tabmenu';
import {TableModule} from 'primeng/table';
import {TabViewModule} from 'primeng/tabview';
import {TerminalModule} from 'primeng/terminal';
import {TieredMenuModule} from 'primeng/tieredmenu';
import {ToastModule} from 'primeng/toast';
import {ToggleButtonModule} from 'primeng/togglebutton';
import {ToolbarModule} from 'primeng/toolbar';
import {TooltipModule} from 'primeng/tooltip';
import {TreeModule} from 'primeng/tree';
import {TreeTableModule} from 'primeng/treetable';
import {VirtualScrollerModule} from 'primeng/virtualscroller';

import {AppComponent} from './app.component';
import {AppMainComponent} from './app.main.component';
import { AppConfigComponent } from './app.config.component';
import {AppNotfoundComponent} from './pages/app.notfound.component';
import {AppErrorComponent} from './pages/app.error.component';
import {AppAccessdeniedComponent} from './pages/app.accessdenied.component';
import {AppLoginComponent} from './pages/app.login.component';
import {AppMenuComponent} from './app.menu.component';
import {AppMenuitemComponent} from './app.menuitem.component';
import {AppTopBarComponent} from './app.topbar.component';
import {AppFooterComponent} from './app.footer.component';
import {AppProfileComponent} from './app.profile.component';
import {AppInvoiceComponent} from './pages/app.invoice.component';
import {AppHelpComponent} from './pages/app.help.component';
import {AppWizardComponent} from './pages/app.wizard.component';

import {MenuService} from './app.menu.service';
import { MessageService } from 'primeng';
import { LicenciaComponent } from './pages/licencia.component';
import { AcercaComponent } from './pages/acerca.component';
import { AvisoComponent } from './pages/aviso.component';
import { PoliticaComponent } from './pages/politica.component';
import { PortadaComponent } from './pages/portada.component';
import { DatosComponent } from './pages/datos.component';
import { BeneficiarioComponent } from './beneficiario/beneficiario.component';
import { DiccionarioGastoComponent } from "./diccionario/gastos/diccionario-gasto.component";

import { EntidadComponent } from './entidad/entidad.component';
import { SociosComponent } from "./socios/socios.component";
import { MarcoComponent } from './pages/marco.component';
import { LoginComponent } from './login/login.component';

import { SessionStorageService } from 'ngx-store';
import { DirectivosComponent } from './directivos/directivos.component';
import { HistoricoComponent } from './historico/historico.component';
import { HistoricoBeneficiarioComponent } from './historicoBeneficiario/historicoBeneficiario.component';
import { BeneficiarioEntidadComponent } from './beneficiarioEntidad/beneficiarioEntidad.component';
import { DiccionarioComponent } from './diccionario/diccionario.component';
import { SociosHistoricoComponent } from './socios/sociosHistorico.component';
import { DirectivosHistoricoComponent } from './directivos/directivosHistorico.component';
import { EntidadBeneficiarioComponent } from './entidad/entidadBeneficiario.component';

import { DiccionarioEntidadComponent } from "./diccionario/entidad/diccionario-entidad.component";
import { DiccionarioBeneficiarioComponent } from './diccionario/beneficiario/diccionario-beneficiario.component';
import { DiccionarioDirectivoComponent } from './diccionario/directivo/diccionario-directivo.component';
import { DiccionarioSocioComponent } from './diccionario/socio/diccionario-socio.component';
import { EntidadPublicoComponent } from './entidad/entidad-publico/entidad-publico.component';
import { BeneficiarioPublicoComponent } from './beneficiario/beneficiario-publico/beneficiario-publico.component';
import { GenerarClaveComponent } from './login/generar-clave/generar-clave.component';

@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        AppRoutes,
        HttpClientModule,
        BrowserAnimationsModule,
        AccordionModule,
        AutoCompleteModule,
        BreadcrumbModule,
        ButtonModule,
        CalendarModule,
        CardModule,
        CarouselModule,
        ChartModule,
        CheckboxModule,
        ChipsModule,
        CodeHighlighterModule,
        ConfirmDialogModule,
        ColorPickerModule,
        ContextMenuModule,
        DataViewModule,
        DialogModule,
        DropdownModule,
        EditorModule,
        FieldsetModule,
        FileUploadModule,
        FullCalendarModule,
        GalleriaModule,
        InplaceModule,
        InputMaskModule,
        InputSwitchModule,
        InputTextModule,
        InputTextareaModule,
        LightboxModule,
        ListboxModule,
        MegaMenuModule,
        MenuModule,
        MenubarModule,
        MessageModule,
        MessagesModule,
        MultiSelectModule,
        OrderListModule,
        OrganizationChartModule,
        OverlayPanelModule,
        PaginatorModule,
        PanelModule,
        PanelMenuModule,
        PasswordModule,
        PickListModule,
        ProgressBarModule,
        RadioButtonModule,
        RatingModule,
        ScrollPanelModule,
        SelectButtonModule,
        SlideMenuModule,
        SliderModule,
        SpinnerModule,
        ProgressSpinnerModule,
        SplitButtonModule,
        StepsModule,
        TableModule,
        TabMenuModule,
        TabViewModule,
        TerminalModule,
        TieredMenuModule,
        ToastModule,
        ToggleButtonModule,
        ToolbarModule,
        TooltipModule,
        TreeModule,
        TreeTableModule,
        VirtualScrollerModule
    ],
    declarations: [
        AppComponent,
        AppMainComponent,
        AppMenuComponent,
        AppMenuitemComponent,
        AppTopBarComponent,
        AppFooterComponent,
        AppProfileComponent,
        AppConfigComponent,
        AppNotfoundComponent,
        AppErrorComponent,
        AppAccessdeniedComponent,
        AppLoginComponent,
        AppInvoiceComponent,
        AppHelpComponent,
        AppWizardComponent,
        DiccionarioEntidadComponent,
        DiccionarioBeneficiarioComponent,
        DiccionarioDirectivoComponent,
        DiccionarioSocioComponent,
        DiccionarioGastoComponent,
        DiccionarioComponent,
        LicenciaComponent,
        AcercaComponent,
        AvisoComponent,
        PortadaComponent,
        DatosComponent,
        PoliticaComponent,
        BeneficiarioComponent,
        EntidadComponent,
        SociosComponent,
        DirectivosComponent,
        MarcoComponent,
        LoginComponent,
        HistoricoComponent,
        HistoricoBeneficiarioComponent,
        BeneficiarioEntidadComponent,
        SociosHistoricoComponent,
        DirectivosHistoricoComponent,
        EntidadBeneficiarioComponent,
        EntidadPublicoComponent,
        BeneficiarioPublicoComponent,
        GenerarClaveComponent
    ],
    providers: [
        {provide: LocationStrategy, useClass: HashLocationStrategy},
        MenuService, MessageService, SessionStorageService
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
