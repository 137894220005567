import { Injectable } from '@angular/core';
import { HttpErrorHandler } from '../util/http.error.handler';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { EntidadTable } from './shared/entidad-table.model';
import { finalize, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EntidadService {

  private url = 'rest/entidad';
  private handler: HttpErrorHandler = new HttpErrorHandler();
  private loading = new BehaviorSubject<boolean>(false);
  private params: HttpParams;

  constructor(private http: HttpClient) { }

  connect(): Observable<boolean> {
    return this.loading.asObservable();
  }

  disconnet(): void {
    this.loading.complete();
  }

  getAllQueryEntidad(start: number, pageSize: number, sortAsc: boolean, sortField: string, ruc, denominacion:string, 
    fechaInscripcion, capitalSuscrito, capitalIntegrado, dictamenConstitucionFecha): Observable<EntidadTable> {
    this.loading.next(true);
    this.params = new HttpParams();
    if (sortField)
      this.params = this.params.set('sortField', sortField);
    if (ruc)
      this.params = this.params.set('ruc', ruc);
    if (denominacion)
      this.params = this.params.set('denominacion', denominacion);
    if (fechaInscripcion)
      this.params = this.params.set('fechaInscripcion', fechaInscripcion);
    if (capitalSuscrito)
      this.params = this.params.set('capitalSuscrito', capitalSuscrito);
    if (capitalIntegrado)
      this.params = this.params.set('capitalIntegrado', capitalIntegrado);

    if (dictamenConstitucionFecha)
      this.params = this.params.set('dictamenConstitucionFecha', dictamenConstitucionFecha);
    /*if (totalPeriodoGasto){
      let totalPeriodoGastoString = totalPeriodoGasto.toString().replace(/\./g,'');
      this.params = this.params.set('totalPeriodoGasto', totalPeriodoGastoString);
    }

    if (totalPeriodoTransferencia){
      let totalPeriodoTransferenciaString = totalPeriodoTransferencia.toString().replace(/\./g,'');
      this.params = this.params.set('totalPeriodoTransferencia', totalPeriodoTransferenciaString);
    }
    if (totalPeriodoSaldoTransferencia){
      let totalPeriodoSaldoTransferenciaString = totalPeriodoSaldoTransferencia.toString().replace(/\./g,'');
      this.params = this.params.set('totalPeriodoSaldoTransferencia', totalPeriodoSaldoTransferenciaString);
    }
    if (cedulaOrdenador) {
        let cedulaOrdenadorString = cedulaOrdenador.toString().replace(/\./g,'');
        this.params = this.params.set('cedulaOrdenador', cedulaOrdenadorString);
    }
    if (nombreDirector)
      this.params = this.params.set('nombreDirector', nombreDirector);

    if (cedulaDirector) {
        let cedulaDirectorString = cedulaDirector.toString().replace(/\./g,'');
        this.params = this.params.set('cedulaDirector', cedulaDirectorString);
    }
    if (fechaCreacion)
      this.params = this.params.set('fechaCreacion', fechaCreacion);*/

    this.params = this.params.set('start', start.toString()).set('pageSize', pageSize.toString()).set('sortAsc', sortAsc.toString());

    return this.http.get<EntidadTable>(this.url + '/getAllQuery',  {params: this.params} );
    
      /*.pipe(finalize(() => { this.loading.next(false) }))
      .pipe(catchError(this.handler.handleError<EntidadTable>('getAllQueryEntidad', new EntidadTable())));*/
  }

  getEntidadesPublico(start: number, pageSize: number, sortAsc: boolean, sortField: string, ruc, denominacion:string, tipoEntidad:string): Observable<EntidadTable> {
    this.loading.next(true);
    this.params = new HttpParams();
    if (sortField)
      this.params = this.params.set('sortField', sortField);
    if (ruc)
      this.params = this.params.set('ruc', ruc);
    if (denominacion)
      this.params = this.params.set('denominacion', denominacion);
    
    if (tipoEntidad)
      this.params = this.params.set('tipoEntidad', tipoEntidad);

    this.params = this.params.set('start', start.toString()).set('pageSize', pageSize.toString()).set('sortAsc', sortAsc.toString());

    return this.http.get<EntidadTable>(this.url + '/getListaPublica',  {params: this.params} );
  }

  getEntidadBeneficiario(start: number, pageSize: number, sortAsc: boolean, sortField: string, ruc, denominacion:string, 
    fechaInscripcion, capitalSuscrito, capitalIntegrado, dictamenConstitucionFecha, rucEntidad): Observable<EntidadTable> {
    this.loading.next(true);
    this.params = new HttpParams();
    if (sortField)
      this.params = this.params.set('sortField', sortField);
    if (ruc)
      this.params = this.params.set('ruc', ruc);
    if (denominacion)
      this.params = this.params.set('denominacion', denominacion);
    if (fechaInscripcion)
      this.params = this.params.set('fechaInscripcion', fechaInscripcion);
    if (capitalSuscrito)
      this.params = this.params.set('capitalSuscrito', capitalSuscrito);
    if (capitalIntegrado)
      this.params = this.params.set('capitalIntegrado', capitalIntegrado);

    if (dictamenConstitucionFecha)
      this.params = this.params.set('dictamenConstitucionFecha', dictamenConstitucionFecha);

    this.params = this.params.set('start', start.toString()).set('pageSize', pageSize.toString()).set('sortAsc', sortAsc.toString());

    return this.http.get<EntidadTable>(this.url + '/beneficiario/'+rucEntidad,  {params: this.params} )
      .pipe(finalize(() => { this.loading.next(false) }))
      .pipe(catchError(this.handler.handleError<EntidadTable>('beneficiario', new EntidadTable()))
    );
  }

  getParamsString():string {
    return this.params.toString();
  }

}
