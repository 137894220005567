import { Component, OnInit, Input, EventEmitter, Output, ChangeDetectorRef} from '@angular/core';
import { SociosService } from './socios.service';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';

import { trigger,state,style,transition,animate } from '@angular/animations';
import { Socio } from './model/socio.model';

import {Entidad} from '../entidad/model/entidad.model';
import { Router } from '@angular/router';


@Component({
  selector: 'app-socios-historico',
  templateUrl: './socios.component.html',
  //styleUrls: ['./socios.component.css'],
  animations: [
    trigger('rowExpansionTrigger', [
        state('void', style({
            transform: 'translateX(-10%)',
            opacity: 0
        })),
        state('active', style({
            transform: 'translateX(0)',
            opacity: 1
        })),

        transition('* <=> *', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
    ])
]
})
export class SociosHistoricoComponent implements OnInit {
  @Input() entidadSeleccionada: Entidad;

  public showContainer: boolean;

  constructor(
    private service: SociosService, public breakpointObserver: BreakpointObserver,
    private cdRef:ChangeDetectorRef, private router: Router
  ) { }


  cols: any[];
  pageSize: number = 10;
  start: number = 0;
  cedula: string;
  block: boolean;
  nombreApellido: string;
  departamento: string;
  distrito: string;
  totalRecords: number = 0;
  sortAsc: boolean = true;
  sortField: string;
  emergenciaGasto: any;
  selectedEmergenciaGasto: any;
  emergenciaGastos: any[];
  //opcionesExtensionFile:any[];
  loading: boolean = true;
  loadingSpinner: boolean = true;
  filter: string;

  id:number;
  mes:string;
  nombreOrganismoFinanciador: string;
  fechaComprobante:Date;
  tipoPresupuesto:string;
  programa:string;
  subprograma:string;
  importe:number;
  idGrupoGasto:number;
  fuenteFinanciamiento:string;
  grupoGastoNombre:string;
  pagoMes:string;
  asientoNro:number;
  fechaObligacion:string;
  asientoEgresoNro:number;
  fechaEgreso:string;
  planillaNro:number;
  resolucionNro:number;
  proveedor:string;
  facturaNro:string;
  facturaCondicion:string;
  facturaFecha:string;
  reciboNro:string;
  reciboFecha:string;
  chequeNro:string;
  bancoNombre:string;
  boletaDeposito:string;
  fechaDeposito:string;
  contratoNro:string;
  contratoFecha:string;
  codigoContratacion:string;
  
  fechaCreacion:string;

  adjuntosGasto: any[];
  cols2: any[];
  pageSizeAdj: number = 10;
  totalRecordsAdj: number = 0;
  datasets: any[];

  socios: any[];

  nombresApellidos:string;
  nroDocumento:string;
  tipoDocumento:string;
  nacionalidad:string;
  profesion:string;
  porcentaje:string;
  cantidadAccionCuota:number;
  valorAccionCuota:number;
  categoriaAccionCuota:number;

  calle:string;

  @Output() bindVisible: EventEmitter<boolean> = new EventEmitter<boolean>(true);
  


  ngOnInit() {
    this.datasets = [
      { numero: '1', nombre: 'Ingresos de Rendición de Cuentas', descripcion: 'Este conjunto de datos contiene los datos de los ingresos recibidos, por el estado de emergencia sanitaria COVID-19, para su rendición de cuentas.', diccionario: '/diccionarioingreso', dataset: '/emergenciaIngresos', csvURL: '/docs/ingresos-full.csv' },
      { numero: '2', nombre: 'Gastos de Rendición de Cuentas', descripcion: 'Este conjunto de datos contiene los datos de gastos usando los ingresos recibidos durante el estado de emergencia sanitaria COVID-19.', diccionario: '/diccionariogasto', dataset: '/emergenciaGastos', csvURL: '/docs/gastos-full.zip' }
    ]

    this.loading = true;
    this.loadingSpinner = false;
    this.emergenciaGasto = {};

    this.breakpointObserver
      .observe(['(max-width:40em)'])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.showContainer = true;
        } else {
          this.showContainer = false;
        }
      });

    // this.renderer.setAttribute(this.elementRef.nativeElement, 'head-columns', 'ui-column-title');
    this.cols = [
      { field: 'denominacion_socio', header: 'Denominación', width: '10%', sortField:'denominacion_socio'},
      { field: 'nro_documento', header: 'Nro Documento', width: '10%', sortField:'nro_documento'},
      { field: 'tipo_identificacion', header: 'Tipo Documento', width: '10%', sortField:'tipo_identificacion'},
      //{ field: 'nacionalidad', header: 'Nacionalidad', width: '10%', sortField:'nacionalidad'},
      { field: 'ocupacion', header: 'Profesión', width: '10%', sortField:'profesion'},
      { field: 'domicilio', header: 'Dirección', width: '10%', sortField:'domicilio'},
      { field: 'porcentaje_capital', header: 'Porcentaje', width: '2%', sortField:'porcentaje_capital' },
      { field: 'cantidad_votos', header: 'Cantidad de Votos', width: '2%', sortField:'cantidad_votos' },
      { field: 'cantidad_acc_cuot_particip', header: 'Cantidad de acciones de cuota de participación', width: '5%', sortField:'cantidad_acc_cuot_particip' },
      { field: 'valor_acc_cuot_particip', header: 'Valor de cada acción', width: '5%', sortField:'valor_acc_cuot_particip' },
      { field: 'categoria_acc_cuout_particip', header: 'Categoría de acciones de cuota de participación', width: '5%', sortField:'categoria_acc_cuout_particip' }
    ];

    /*this.opcionesExtensionFile=[
      {value:null,label:"Sin Filtro"},
      {value:'pdf',label:"pdf"},
      {value:'otros',label:"Otros"}
    ];*/
  }

  ngAfterViewChecked(){
    //this.dateNow = new Date();
    this.cdRef.detectChanges();
  }

  ngOnDestroy() {
    this.service.disconnet();
  }

  onFilterChange($event, dt) {
    if ($event) {
      this.loadingSpinner = true;
      switch (dt) {
        case 'denominacion':
          this.nombresApellidos = $event.target.value;
          break;
        case 'nroDocumento':
          this.nroDocumento = $event.target.value;
          break;
        case 'tipoIdentificacion':
          this.tipoDocumento = $event.target.value;
          break;
        case 'nacionalidad':
          this.nacionalidad = $event.target.value;
          break;
        case 'ocupacion':
            this.profesion = $event.target.value;
            break;
        case 'porcentaje':
            this.porcentaje = $event.target.value;
            break;
        case 'cantAccionCuota':
              this.cantidadAccionCuota = $event.target.value;
              break;
        case 'valorAccionCuota':
            this.valorAccionCuota = $event.target.value;
            break;
        case 'categoriaAccionCuota':
            this.categoriaAccionCuota = $event.target.value;
            break;
        case 'calle':
            this.calle = $event.target.value;
            break;
        case 'proveedor':
              this.proveedor = $event.target.value;
              break;
        case 'nro_documento':
                this.nroDocumento = $event.target.value;
                break;
        case 'fecha_factura':
            this.facturaFecha = $event.target.value;
            break;
        case 'contrato_nro':
          this.contratoNro = $event.target.value;
          break;
        case 'fecha_contrato':
          this.contratoFecha = $event.target.value;
          break;
        case 'codigo_contratacion':
            this.codigoContratacion = $event.target.value;
            break;       
        case 'fecha_creacion':
            this.fechaCreacion = $event.target.value;
            break;
        default:
      }
      this.loadSocios();
    }
  }

  load($event: any) {
    this.loading = true;
    if ($event) {
      this.start = $event.first;
      this.pageSize = $event.rows;
      this.sortField = $event.sortField;

      if ($event.sortOrder == 1)
        this.sortAsc = true;
      else
        this.sortAsc = false;
    }
    this.loadSocios();
  }

  private loadSocios() {
    this.service.getHistoricoSocios(this.start, this.pageSize, this.sortAsc, this.sortField,
      this.nombresApellidos, this.nroDocumento, this.tipoDocumento, this.profesion, this.cantidadAccionCuota,
      this.valorAccionCuota, this.categoriaAccionCuota, this.calle, this.entidadSeleccionada.id).subscribe(socios => {
        this.loading = false;
        this.socios = socios.lista;
        this.loadingSpinner = false;
        this.totalRecords = socios.totalRecords;
      }, error => {
        if(error.status == 401){
          this.loading = false;
          this.router.navigate(["/login"]);
        } 
      });
  }

  close() {
    this.bindVisible.emit(false);
  }
  /*descargaBeneficiarioDownload(data: Beneficiario) {
    window.open("/rest/pytyvo/getFileDocument/"+data.id);
  }

  listadoPdf() {
    window.open("/rest/pytyvo/getAllQueryReport?"+this.service.getParamsString());
  }

  listadoXls() {
    window.open("/rest/pytyvo/getAllQueryReport?"+this.service.getParamsString()+"&type=xls");
  }

  listadoCsv() {
    window.open("/rest/pytyvo/getAllQueryReport?"+this.service.getParamsString()+"&type=csv");
  }*/

  openCsvFullLink() {
    window.open('/docs/gastos-full.zip', '_blank');
  }

}
