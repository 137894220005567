import { Component, OnInit, Input, EventEmitter, Output, ChangeDetectorRef, ElementRef, ViewChild} from '@angular/core';
import { HistoricoService } from './historico.service';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';

import { trigger,state,style,transition,animate } from '@angular/animations';
//import { Directivo } from './model/directivo.model';

import {Entidad} from '../entidad/model/entidad.model'

@Component({
  selector: 'app-historico',
  templateUrl: './historico.component.html',
  //styleUrls: ['./socios.component.css'],
  animations: [
    trigger('rowExpansionTrigger', [
        state('void', style({
            transform: 'translateX(-10%)',
            opacity: 0
        })),
        state('active', style({
            transform: 'translateX(0)',
            opacity: 1
        })),

        transition('* <=> *', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
    ])
]
})
export class HistoricoComponent implements OnInit {
  @ViewChild('scrollMe') scrollMe: ElementRef;
  @ViewChild('scrollDirectivos') scrollDirectivos: ElementRef;
  @Input() entidadSeleccionada: Entidad;

  public showContainer: boolean;

  constructor(
    private service: HistoricoService,
    public breakpointObserver: BreakpointObserver,
    private cdRef:ChangeDetectorRef
  ) { }


  cols: any[];
  pageSize: number = 10;
  start: number = 0;
  cedula: string;
  block: boolean;
  nombreApellido: string;
  departamento: string;
  distrito: string;
  totalRecords: number = 0;
  sortAsc: boolean = true;
  sortField: string;
  emergenciaGasto: any;
  selectedEmergenciaGasto: any;
  emergenciaGastos: any[];
  //opcionesExtensionFile:any[];
  loading: boolean = true;
  loadingSpinner: boolean = true;
  filter: string;

  id:number;
  mes:string;
  nombreOrganismoFinanciador: string;
  fechaComprobante:Date;
  tipoPresupuesto:string;
  pagoMes:string;
  asientoNro:number;
  fechaObligacion:string;
  asientoEgresoNro:number;
  fechaEgreso:string;
  planillaNro:number;
  resolucionNro:number;
  proveedor:string;
  facturaFecha:string;
  reciboNro:string;
  contratoNro:string;
  contratoFecha:string;
  codigoContratacion:string;
  
  fechaCreacion:string;

  adjuntosGasto: any[];
  
  totalRecordsAdj: number = 0;
  datasets: any[];

  historico: any[];

  denominacion:string;
  rucNro:string;
  origen:string;
  fechaInscripcion;
  capitalSuscrito;
  capitalIntegrado;
  dictamenConstitucionFecha;
  selectedBeneficiarioTemp;
  selectedBeneficiario;

  selectedHistoricoTemp;
  selectedHistorico;

  selectedDirectivoTemp;
  selectedDirectivo;

  selectedSocioTemp;
  selectedSocio;

  @Output() bindVisible: EventEmitter<boolean> = new EventEmitter<boolean>(true);

  ngOnInit() {
    this.datasets = [
      { numero: '1', nombre: 'Ingresos de Rendición de Cuentas', descripcion: 'Este conjunto de datos contiene los datos de los ingresos recibidos, por el estado de emergencia sanitaria COVID-19, para su rendición de cuentas.', diccionario: '/diccionarioingreso', dataset: '/emergenciaIngresos', csvURL: '/docs/ingresos-full.csv' },
      { numero: '2', nombre: 'Gastos de Rendición de Cuentas', descripcion: 'Este conjunto de datos contiene los datos de gastos usando los ingresos recibidos durante el estado de emergencia sanitaria COVID-19.', diccionario: '/diccionariogasto', dataset: '/emergenciaGastos', csvURL: '/docs/gastos-full.zip' }
    ]

    this.loading = true;
    this.loadingSpinner = false;
    this.emergenciaGasto = {};

    this.breakpointObserver
      .observe(['(max-width:40em)'])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.showContainer = true;
        } else {
          this.showContainer = false;
        }
      });

    // this.renderer.setAttribute(this.elementRef.nativeElement, 'head-columns', 'ui-column-title');
      //, width: '2%'
     //Grilla empresas
     this.cols = [
      { field: 'ruc_nro', header: 'RUC', width: '10%', sortField:'ruc_nro' },
      { field: 'denominacion', header: 'Denominación', width: '25%', sortField:'denominacion' },
      { field: 'capital_suscrito', header: 'Capital Suscrito (Gs.)', width: '15%', sortField:'capital_suscrito_sort' },
      { field: 'capital_integrado', header: 'Capital Integrado (Gs.)', width: '15%', sortField:'capital_integrado_sort' },
      { field: 'dictamen_constitucion_fecha', header: 'Fecha Dictamen Constitución', width: '10%', sortField:'dictamen_constitucion_fecha' },
      { field: 'origen', header: 'Origen del Trámite', width: '15%', sortField:'origen' },
      { field: 'fecha_inscripcion', header: 'Fecha de Última Actualización', width: '10%', sortField:'fecha_inscripcion' }
      ];
  }

  ngAfterViewChecked(){
    this.cdRef.detectChanges();
  }

  verBeneficiarios(rowData){
    this.selectedBeneficiarioTemp = rowData;
    this.selectedBeneficiario = null;

    //Para ocultar las otras grillas
    this.selectedDirectivo = null;
    this.selectedSocio = null;

    setTimeout(() => {
      this.selectedBeneficiario = this.selectedBeneficiarioTemp;
    }, 0);
    this.scrollMe.nativeElement.scrollIntoView({behavior: 'smooth'});
  }

  verDirectivos(rowData){
    this.selectedDirectivoTemp = rowData;
    this.selectedDirectivo = null;

    //Para ocultar las otras grillas
    this.selectedBeneficiario = null;
    this.selectedSocio = null;

    setTimeout(() => {
      this.selectedDirectivo = this.selectedDirectivoTemp;
    }, 0);
    this.scrollDirectivos.nativeElement.scrollIntoView({behavior: 'smooth'});
  }

  verSocios(rowData){
    this.selectedSocioTemp = rowData;
    this.selectedSocio = null;

    //Para ocultar las otras grillas
    this.selectedBeneficiario = null;
    this.selectedDirectivo = null;

    setTimeout(() => {
      this.selectedSocio = this.selectedSocioTemp;
    }, 0);
    this.scrollDirectivos.nativeElement.scrollIntoView({behavior: 'smooth'});
  }

  ngOnDestroy() {
    this.service.disconnet();
  }

  onFilterChange($event, dt) {
    if ($event) {
      this.loadingSpinner = true;
      switch (dt) {
        case 'denominacion':
          this.denominacion = $event.target.value;
          break;
        case 'rucNro':
          this.rucNro = $event.target.value;
          break;
        case 'fechaInscripcion':
            this.fechaInscripcion = $event.target.value;
            break;
        case 'capitalSuscrito':
              this.capitalSuscrito = $event.target.value;
              break;
        case 'capitalIntegrado':
              this.capitalIntegrado = $event.target.value;
              break;
        case 'dictamenConstitucionFecha':
              this.dictamenConstitucionFecha = $event.target.value;
              break;
        case 'origen':
            this.origen = $event.target.value;
            break;
        case 'fecha_creacion':
            this.fechaCreacion = $event.target.value;
            break;
        default:
      }
      this.loadHistorico();
    }
  }

  load($event: any) {
    this.loading = true;
    if ($event) {
      //this.filter = $event.globalFilter;
      this.start = $event.first;
      this.pageSize = $event.rows;
      this.sortField = $event.sortField;

      if ($event.sortOrder == 1)
        this.sortAsc = true;
      else
        this.sortAsc = false;
    }
    this.loadHistorico();
  }

  private loadHistorico() {
    this.service.getAllQueryHistorico(this.start, this.pageSize, this.sortAsc, this.sortField, this.entidadSeleccionada.ruc_nro, 
      this.denominacion, this.fechaInscripcion, this.capitalSuscrito, this.capitalIntegrado, this.dictamenConstitucionFecha, this.origen).subscribe(historico => {
        this.loading = false;
        this.historico = historico.lista;
        this.loadingSpinner = false;
        this.totalRecords = historico.totalRecords;

      });
  }

  close() {
    this.bindVisible.emit(false);
  }

  openCsvFullLink() {
    window.open('/docs/gastos-full.zip', '_blank');
  }

}
