import { Component } from '@angular/core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { StorageManagerService } from '../login/shared/storage-manager.service';


@Component({
  selector: 'app-politica',
  templateUrl: './politica.component.html',
})
export class PoliticaComponent {
  public showContainer: boolean;
  politica: any[];
  esPaginaPublica:boolean = false;

  constructor(public breakpointObserver: BreakpointObserver/*, private storageManager: StorageManagerService*/) { }

  ngOnInit(): void {
   // if(this.storageManager.getLoginData()) {
      this.politica = [
        { numero: '1', conjunto: 'Datos de  Personas Jurídicas', anho: 'Desde 2020', periodo: 'Diario'},
        { numero: '2', conjunto: 'Datos de Beneficiarios', anho: 'Desde 2020', periodo: 'Diario'}
      ]
    /*} else {
      this.politica = [
        { numero: '1', conjunto: 'Datos de Entidades', anho: 'Desde 2020', periodo: 'Diario'}
      ]
    }*/
    this.breakpointObserver
      .observe(['(max-width:40em)'])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.showContainer = true;
        } else {
          this.showContainer = false;
        }
      });
  }


}
