import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { Usuario } from '../../usuario/model/usuario.model';
import { LoginService } from '../shared/login.service'

@Component({
  selector: 'app-generar-clave',
  templateUrl: './generar-clave.component.html',
  styleUrls: ['./generar-clave.component.css']
})
export class GenerarClaveComponent implements OnInit {

  formGroup: FormGroup;
  user: Usuario;

  constructor(
    private service: LoginService, 
    private router: Router
  ) { }

  ngOnInit() {
    this.formGroup = new FormGroup({

      username: new FormControl('', [ Validators.required, Validators.email ])
      
    });

    this.user = new Usuario();
  }

  enviarCorreo() {
    this.user.username = this.formGroup.controls.username.value;
    
    this.service.sendPasswordEmail(this.user).subscribe(res => {
      this.router.navigate(["/login"], { queryParams: { status: res.status, message: res.message } });
      })
    }
}
