import { Injectable } from '@angular/core';
import { HttpErrorHandler } from '../../util/http.error.handler';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { finalize, catchError } from 'rxjs/operators';
import { UsernamePassword } from '../model/username-password.model';

import { Credentials } from '../model/credentials.model';
import { StorageManagerService } from './storage-manager.service';
import { Usuario } from '../../usuario/model/usuario.model';
import { MessageResponse } from 'src/app/beneficiario/model/message-response.model';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  private authenticationUrl = 'rest/seguridad';
  private urlAbtAdmin = 'api/usuario';

  private handler: HttpErrorHandler = new HttpErrorHandler();
  private loading = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient, private storageManager: StorageManagerService) { }


  doLogin(usernamePassword: UsernamePassword): Observable<Credentials> {
    return this.http.post<Credentials>(this.authenticationUrl, usernamePassword).pipe(catchError(this.handler.handleError<Credentials>('doLogin', new Credentials())));
  }

  doLogout() {
    this.storageManager.deleteStorage();
  }

  sendPasswordEmail(usuario: Usuario) {
    this.loading.next(true);
    return this.http.post<MessageResponse>(this.urlAbtAdmin + '/enviarCorreo', usuario)
      .pipe(finalize(() => { this.loading.next(false) }))
      .pipe(catchError(this.handler.handlePostError<MessageResponse>('sendPasswordEmail')));
  }

}
