import { Injectable } from '@angular/core';
import { HttpErrorHandler } from '../util/http.error.handler';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { finalize, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TipoEntidadService {

  private url = 'rest/tipoEntidad';
  private handler: HttpErrorHandler = new HttpErrorHandler();
  private loading = new BehaviorSubject<boolean>(false);
  private params: HttpParams;

  constructor(private http: HttpClient) { }

  connect(): Observable<boolean> {
    return this.loading.asObservable();
  }

  disconnet(): void {
    this.loading.complete();
  }

  getList(): Observable<any> {
    this.loading.next(true);

    return this.http.get<any>(this.url + '/getAllQuery');
  }

  getParamsString():string {
    return this.params.toString();
  }

}
