import { Component, OnInit, EventEmitter, Output, OnDestroy, ViewChild, Renderer2, ElementRef } from '@angular/core';
import { EntidadService } from '../../entidad/entidad.service';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Router } from '@angular/router';
import { Entidad } from '../model/entidad.model';

@Component({
  selector: 'app-entidad-publico',
  templateUrl: './entidad-publico.component.html',
  styleUrls: ['./entidad-publico.component.css']
})
export class EntidadPublicoComponent implements OnInit, OnDestroy {
  @ViewChild('scrollMe') scrollMe: ElementRef;
  @ViewChild('scrollHistorico') scrollHistorico: ElementRef;


  public showContainer: boolean;

  constructor(private service: EntidadService, public breakpointObserver: BreakpointObserver, private router: Router) { }

  fechaActualizacion: Date;
  cols: any[];
  pageSize: number = 10;
  start: number = 0;
  ejercicioFiscal: number;
  block: boolean;
  entidad: string;
  nroRectificada: number;
  id: number;
  periodoDesde: string;
  periodoHasta: string;
  totalPeriodoGasto: number;
  totalPeriodoTransferencia: number;
  totalPeriodoSaldoTransferencia: number;
  nombreOrdenador: string;
  cedulaOrdenador: string;
  nombreDirector: string;
  cedulaDirector: string;
  fechaCreacion: string;
  totalRecords: number = 0;
  sortAsc: boolean = true;
  sortField: string;

  selectedVerSocios: any;

  // opcionesExtensionFile:any[];
  loading: boolean = true;
  loadingSpinner: boolean = true;
  filter: string;
  entidades: Entidad[];

 
  selectedBeneficiarioEnti;
  selectedBeneficiarioEntiTemp;

  ruc:string;
  denominacion:string;
  tipoEntidad:string;
  fechaInscripcion;
  capitalSuscrito;
  capitalIntegrado;
  dictamenConstitucionFecha;
  mensajeExport:string;

  @Output() bindVisible: EventEmitter<boolean> = new EventEmitter<boolean>(true);

  ngOnInit() {
    this.loading = true;
    this.loadingSpinner = false;
    this.fechaActualizacion = new Date();
    if (this.fechaActualizacion.getHours() < 7) {
        this.fechaActualizacion.setDate(this.fechaActualizacion.getDate() - 1);
    }

    this.breakpointObserver
      .observe(['(max-width:40em)'])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.showContainer = true;
        } else {
          this.showContainer = false;
        }
      });

    this.cols = [ 
      //Grilla empresas
      { field: 'ruc_nro', header: 'RUC', width: '5%', sortField:'ruc_nro' },
      { field: 'denominacion', header: 'Denominación', width: '25%', sortField:'denominacion' },
      { field: 'tipo_entidad', header: 'Tipo', width: '25%', sortField:'tipo_entidad' }
    ];
  }

  ngOnDestroy() {
    this.service.disconnet();
  }


  openCsvFullLink() {
    window.open('https://drive.google.com/drive/folders/1Y7bO7WsqesKi67zczUup0m8HzL9BQE3_?usp=sharing',  '_blank');
  }

  

  onFilterChange($event, dt) {
    if ($event) {

      this.loadingSpinner = true;
      this.start = 0;
      switch (dt) {
        case 'ruc':
          this.ruc = $event.target.value;
          break;
        case 'denominacion':
          this.denominacion = $event.target.value;
          break; 
          case 'tipo_entidad':
          this.tipoEntidad = $event.target.value;
          break; 
        default:
      }
      this.loadEntidades();
    }
  }

  load($event: any) {
    this.loading = true;
    if ($event) {
      this.start = $event.first;
      this.pageSize = $event.rows;
      this.sortField = $event.sortField;

      if ($event.sortOrder == 1)
        this.sortAsc = true;
      else
        this.sortAsc = false;
    }
    this.loadEntidades();
  }

  private loadEntidades() {
    this.pageSize=10;
    this.service.getEntidadesPublico(this.start, this.pageSize, this.sortAsc, this.sortField, this.ruc, 
      this.denominacion, this.tipoEntidad).subscribe(entidades => {
        this.loading = false;
        this.entidades = entidades.lista;
        this.loadingSpinner = false;
        this.totalRecords = entidades.totalRecords;
        if(this.totalRecords<1000)
        {
          this.mensajeExport="Lista filtrada en CSV";
        }
        else
        {
          this.mensajeExport="La búsqueda devuelve demasiados registros. Debe refinar más su búsqueda.";
        }
      }, error => {
        if(error.status == 401){
          this.loading = false;
          this.router.navigate(["/login"]);
        }
      });
  }

  close() {
    this.bindVisible.emit(false);
  }

  csv(table:any) {
    this.loading = true;
    this.pageSize=2147483647;
    this.service.getEntidadesPublico(this.start, this.pageSize, this.sortAsc, this.sortField, this.ruc, 
      this.denominacion, this.tipoEntidad).subscribe(entidades => {
        this.loading = false;
        this.entidades = entidades.lista;
        this.loadingSpinner = false;
        this.totalRecords = entidades.totalRecords;
        setTimeout(() => {
          table.exportCSV();
        }, 0);
      }, error => {
        if(error.status == 401){
          this.loading = false;
          this.router.navigate(["/login"]);
        }
      });
  }

}
