import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class DiccionarioService {

  constructor(private http: HttpClient) { }

    public getDiccionarioGasto(): Observable<any> {
        return this.http.get('./assets/json/diccionarioGasto.json');
    }

    getDiccionarioIngreso(): Observable<any> {
        return this.http.get('./assets/json/diccionarioIngreso.json');
    }

    getDiccionarioEntidad(): Observable<any> {
      return this.http.get('./assets/json/diccionarioEntidad.json');
    }

    getDiccionarioEntidadPublico(): Observable<any> {
      return this.http.get('./assets/json/diccionarioEntidadPublico.json');
    }

    getDiccionarioBeneficiario(): Observable<any> {
      return this.http.get('./assets/json/diccionarioBeneficiario.json');
    }

    getDiccionarioBeneficiarioPublico(): Observable<any> {
      return this.http.get('./assets/json/diccionarioBeneficiarioPublico.json');
    }

    getDiccionarioDirectivo(): Observable<any> {
      return this.http.get('./assets/json/diccionarioDirectivo.json');
    }

    getDiccionarioSocio(): Observable<any> {
      return this.http.get('./assets/json/diccionarioSocio.json');
    }
}
