import { Component, OnInit } from '@angular/core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { DiccionarioService } from '../diccionario.service';

import { Router } from '@angular/router';
import { StorageManagerService } from '../../login/shared/storage-manager.service';

@Component({
  selector: 'app-diccionario-beneficiario',
  templateUrl: './diccionario-beneficiario.component.html',
  styleUrls: ['./diccionario-beneficiario.component.css']
})
export class DiccionarioBeneficiarioComponent implements OnInit {
  diccionario: any;
  public showContainer: boolean;
  public estaLogueado: boolean = false;

  constructor(public breakpointObserver: BreakpointObserver, private diccionarioService: DiccionarioService, private router: Router, private storageManager: StorageManagerService) { }

  ngOnInit(): void {
    
    if(this.storageManager.getLoginData()) {
      this.estaLogueado = true;
      this.diccionarioService.getDiccionarioBeneficiario().subscribe(data => {
        this.diccionario = data;
      }, error => {
        if(error.status == 401){
          //this.loading = false;
          this.router.navigate(["/login"]);
        } 
      });
    } else {
      this.estaLogueado = false;
      this.diccionarioService.getDiccionarioBeneficiarioPublico().subscribe(data => {
        this.diccionario = data;
      });
    }


    

    this.breakpointObserver
      .observe(['(max-width:40em)'])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.showContainer = true;
        } else {
          this.showContainer = false;
        }
      });
  }

  openCsvBeneficiarios() {
    if(this.estaLogueado == true){
      window.open('assets/docs/beneficiario-final.csv', '_blank');
    } else {
      window.open('assets/docs/beneficiario-final-publico-diccionario.csv', '_blank');
    }
    
  }

}
