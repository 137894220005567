import { Component } from '@angular/core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { StorageManagerService } from '../login/shared/storage-manager.service';

@Component({
  selector: 'app-datos',
  templateUrl: './datos.component.html'
})
export class DatosComponent {
  datasets: any[];
  explorar: any[];
  public showContainer: boolean;
  esPaginaPublica:boolean = false;
  constructor(
    public breakpointObserver: BreakpointObserver,
    private storageManager: StorageManagerService
  ) { }

  ngOnInit() {

    if(this.storageManager.getLoginData()) {
      this.esPaginaPublica = false;
      this.datasets = [
        { numero: '1', nombre: 'Personas Jurídicas', descripcion: 'Este conjunto de datos contiene los datos de las personas jurídicas registradas.', diccionario: '/diccionarioentidad', dataset: '/entidad', csvURL: '/docs/entidad-full.csv', md5URL: '/docs/entidad-full.md5' },
        { numero: '2', nombre: 'Beneficiarios Finales', descripcion: 'Este conjunto de datos contiene los datos de los beneficiarios finales.', diccionario: '/diccionariobeneficiario', dataset: '/beneficiario', csvURL: '/docs/beneficiario-final-full.csv', md5URL: '/docs/beneficiario-final-full.md5' },
        { numero: '3', nombre: 'Directivos', descripcion: 'Este conjunto de datos contiene los datos de los directivos de las entidades.', diccionario: '/diccionariodirectivo', dataset: '/entidad', csvURL: './docs/directivos-full.csv', md5URL: '/docs/directivos-full.md5' },
        { numero: '4', nombre: 'Socios', descripcion: 'Este conjunto de datos contiene los datos de los socios de las entidades.', diccionario: '/diccionariosocio', dataset: '/entidad', csvURL: '/docs/socios-full.csv', md5URL: '/docs/socios-full.md5' }
      ];

      this.explorar = [
        {
          nombre: 'Explorar Datos de Personas Jurídicas', descripcion: 'Opción de  explorar y navegar por la colección de datos. Al acceder, visualizará una tabla con la lista de las personas jurídicas registradas.', dataset: '/entidad'
        },
        {
          nombre: 'Explorar Datos de Beneficiarios', descripcion: 'Opción de  explorar y navegar por la colección de datos. Al acceder, visualizará una tabla con la lista de los beneficiarios finales.', dataset: '/beneficiario'
        }
      ];
    } else {
      this.esPaginaPublica = true;

      this.datasets = [
        { numero: '1', nombre: 'Personas Jurídicas', descripcion: 'Este conjunto de datos contiene los datos de las personas jurídicas registradas.', diccionario: '/diccionarioentidad', dataset: '/entidad-publico', csvURL: '/docs/entidad-publico.csv', md5URL: '/docs/entidad-publico.md5' },
        { numero: '2', nombre: 'Beneficiarios Finales', descripcion: 'Este conjunto de datos contiene los datos de los beneficiarios finales.', diccionario: '/diccionariobeneficiario', dataset: '/beneficiario-publico', csvURL: '/docs/beneficiario-final-publico.csv', md5URL: '/docs/beneficiario-final-publico.md5' },
      ];

      this.explorar = [
        { nombre: 'Explorar Datos de Personas Jurídicas', descripcion: 'Opción de  explorar y navegar por la colección de datos. Al acceder, visualizará una tabla con la lista de las personas jurídicas registradas.', dataset: '/entidad-publico'},
        { nombre: 'Explorar Datos de Beneficiarios', descripcion: 'Opción de  explorar y navegar por la colección de datos. Al acceder, visualizará una tabla con la lista de los beneficiarios finales.', dataset: '/beneficiario-publico'}
      ];
    }
    this.breakpointObserver
        .observe(['(max-width:40em)'])
        .subscribe((state: BreakpointState) => {
          if (state.matches) {
            this.showContainer = true;
          } else {
            this.showContainer = false;
          }
        });
  }

    openCsvFullLink(link) {
        window.open(link, '_blank');
    }
}
