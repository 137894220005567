import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate } from '@angular/router';
import { StorageManagerService } from '../login/shared/storage-manager.service';

@Injectable({
  providedIn: 'root'
})
export class SessionGuardService implements CanActivate {

  constructor(private storageManager: StorageManagerService, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    let login = this.storageManager.getLoginData();

    if (login)
      return true;

    this.router.navigate(['/login']);
    return false;
  }
}