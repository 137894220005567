import {Component, OnInit} from '@angular/core';
import {AppMainComponent} from './app.main.component';

import { Router, NavigationEnd } from '@angular/router';
import { Location } from '@angular/common';

import { LoginService } from './login/shared/login.service';
import { StorageManagerService } from './login/shared/storage-manager.service';

@Component({
    selector: 'app-menu',
    template: `
    <ul class="layout-menu layout-main-menu clearfix" style="margin-top: 30px;">
        <li app-menuitem *ngFor="let item of model; let i = index;" [item]="item" [index]="i" [root]="true"></li>
        <li style="float:right; margin-right: 96px;" (click)="logout($event)" *ngIf="!isLoginPag && mostrarOpcionCerrarSesion">
            <a href="#"><i class="fa fa-fw fa-sign-out"></i>
            <span>Cerrar sesión</span></a>
        </li>
    </ul>
    `
})
export class AppMenuComponent implements OnInit {//*ngIf="isLoginPag==false"

    model: any[];
    isLoginPag:boolean;
    mostrarOpcionCerrarSesion:boolean = false;
    //isLoginPag:boolean=null;

    constructor(public app: AppMainComponent, private location: Location, private router: Router, private service: LoginService, private storageManager: StorageManagerService) {}

    ngOnInit() {
        if(this.location.path()==='/login' || this.location.path() === '/generarClave'){
            this.isLoginPag = true;
            this.mostrarOpcionCerrarSesion = false;
        }else{
            this.isLoginPag = false;
            this.model = [
                {label: 'Portada', icon: 'fa fa-fw  fa-home', routerLink: ['/portada']},
                {label: 'Conjuntos de datos', icon: 'fa fa-fw  fa-database', routerLink: ['/datos']},
                {label: 'Licencia', icon: 'fa fa-fw fa-file-text-o', routerLink: ['/licencia']},
                {label: 'Política de publicación', icon: 'fa fa-fw  fa-calendar-check-o', routerLink: ['/politica']},
                {label: 'Aviso legal', icon: 'fa fa-gavel', routerLink: ['/aviso']},
                {label: 'Acerca de', icon: 'fa fa-fw fa-info-circle', routerLink: ['/acerca']},
                {label: 'Marco normativo', icon: 'fa fa-fw fa-folder', routerLink: ['/marco']}
            ];

            if(this.storageManager.getLoginData()) {
                this.mostrarOpcionCerrarSesion = true;
            } else {
                this.mostrarOpcionCerrarSesion = false;
            }
        }
        
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                if ((this.location.path().startsWith('/login')) || (this.location.path().startsWith('/generarClave'))) {
                    this.isLoginPag = true;
                    this.model = [];
                    this.mostrarOpcionCerrarSesion = false;
                }else{
                    this.isLoginPag = false;
                    this.model = [
                        {label: 'Portada', icon: 'fa fa-fw  fa-home', routerLink: ['/portada']},
                        {label: 'Conjuntos de datos', icon: 'fa fa-fw  fa-database', routerLink: ['/datos']},
                        {label: 'Licencia', icon: 'fa fa-fw fa-file-text-o', routerLink: ['/licencia']},
                        {label: 'Política de publicación', icon: 'fa fa-fw  fa-calendar-check-o', routerLink: ['/politica']},
                        {label: 'Aviso legal', icon: 'fa fa-gavel', routerLink: ['/aviso']},
                        {label: 'Acerca de', icon: 'fa fa-fw fa-info-circle', routerLink: ['/acerca']},
                        {label: 'Marco normativo', icon: 'fa fa-fw fa-folder', routerLink: ['/marco']}
                    ];
                    if(this.storageManager.getLoginData()) {
                        this.mostrarOpcionCerrarSesion = true;
                    } else {
                        this.mostrarOpcionCerrarSesion = false;
                    }
                }
            } 
        });
    }

    logout() {
        event.preventDefault();
        this.service.doLogout();
        this.router.navigate(['/login']);
    }
}
